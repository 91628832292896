.TravellerTicket-holder{
    height: max-content;
    width: 100%;
    margin-bottom: 1rem;
}
.TravellerTicket-holder-web{
    height: max-content;
    width: 100%;
}
.TravellerTicket-holder-mobile{
    display: none;
}
.travellerTicket-sec1{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding-inline: 1rem;
}
.travellerTicket-sec1 select{
    border: 0;
    background-color: transparent;
    border-radius: 0;
    width: 6%;
    height: max-content;
    box-shadow: none;
}
.traveller-sec1-input{
    width: 12%;
    height: 50%;
    border: solid 1px rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 1rem;
    padding-inline: 5px;
}
.traveller-sec1-input input{
    width: 90%;
    border: none;
    background-color: transparent;
}
.travellerTicket-sec2-holder{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.travellerTicket-sec2-top{
    width: 90%;
    height: 7vh;
    display: flex;
    border-top: solid 1px rgba(0, 0, 0, 0.26);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
    border-radius: 10px 10px  0 0;
    align-items: center;
    justify-content: space-around;
}
.travellerTicket-sec2-topItems{
    width: 15%;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.travellerTicket-item{
    width: 90%;
    height: 15vh;
    display: flex;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
    border-radius:  0 0 10px 10px ;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    font-weight: 600;
}
.p-ticketID{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-TransportCompany{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-destination{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}
.p-TripType{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-amount{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-date{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.p-date-holder{
    width: 100%;
    height: 15%;
    display: flex;
    color: aqua;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding-right: 2rem;
    cursor: pointer;
}
.destination-item1{
    display: flex;
    height: 40%;
    align-items: center;
    gap: 10px;
}
.travellerTicket-navigation{
    width: 90%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.navBack{
    width: 20px;
    height: 20px;
    border: solid 1px rgba(0, 0, 0, 0.281);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.navForward{
    width: 20px;
    height: 20px;
    border: solid 1px rgba(0, 0, 0, 0.281);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.navPageNumber-holder{
    width: max-content;
    display: flex;
    align-items: center;
    gap: 10px;
}


@media only screen and (min-width:320px) and (max-width:768px) {
    .TravellerTicket-holder-web{
       display: none;
    }
    .TravellerTicket-holder-mobile{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: max-content;
        align-items: center;
    }
    .TravellerTicket-holder-mobile-top{
        width: 90%;
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row-reverse;
    }
    .TravellerTicket-holder-mobile-top select{
            width: 30%;
            height: max-content;
            border: none;
            background-color: transparent;
            box-shadow: none;
    }
    .TravellerTicket-holder-mobile-top-input{
        width: 50%;
        height: 40%;
        border: solid 1px rgba(0, 0, 0, 0.253);
        display: flex;
        align-items: center;
        padding-inline-start: 10px;
        border-radius: 5px;
    }
    .TravellerTicket-holder-mobile-top-input input{
        background-color: transparent;
        border: none;
        width: max-content;
    }
    .TravellerTicket-holder-mobile-itemHolder{
        width: 90%;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .TravellerTicket-holder-mobile-items{
        width: 100%;
        height: 45vh;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
    }
    .TravellerTicket-holder-mobile-item1{
        width: 100%;
        height: 20%;
        border-bottom: solid 1px rgba(0, 0, 0, 0.253);
        display: flex;
    }
    .TravellerTicket-holder-mobile-item1a{
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
    }
    .TravellerTicket-holder-mobile-item2{
        width: 100%;
        height: 15%;
        border-bottom: solid 1px rgba(0, 0, 0, 0.253);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 1rem;
    }
    .TravellerTicket-holder-mobile-item3{
        width: 100%;
        height: 20%;
        display: flex;
        color: aqua;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        padding-inline: 1rem;
        
    }
}