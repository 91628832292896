.traveleerSide-ttransporcompanies-container{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
}
.traveleerSide-ttransporcompanies-topsec{
    width: 100;
    height: 30vh;
    background-image: url("../../images/backgrndImg1.png");
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.traveleerSide-ttransporcompanies-item1{
    width: 100%;
    height: 10vh;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding-inline: 5%;
}
.traveleerSide-ttransporcompanies-item1a{
    width: 20%;
    height: 45%;
    align-items: center;
    /* justify-content: space-between; */
    gap: 0.5rem;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    padding-inline: 1rem;
}
.traveleerSide-ttransporcompanies-item1a input{
    background: transparent;
    border: none;

}
.traveleerSide-ttransporcompanies-item1a select{
    background: transparent;
    border: none;
    width: 90%;
    box-shadow: none;
}
.traveleerSide-ttransporcompanies-item1a option{
    background: transparent;
    border: none;
    width: 90%;
}
.traveleerSide-ttransporcompanies-item-container{
    width: 100%;
    height: max-content;
    min-height: 50vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.tansportCompanies-btnHolder{
    display: none;
}
.traveleerSide-ttransporcompanies-item-container-items{
    width: 90%;
    height: 20vh;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    display: flex;
    padding-inline: 5%;
    align-items: center;
    justify-content: space-between;
}
.traveleerSide-ttransporcompanies-item-container-items img{
    height: 70%;
    width: 15%;
    border-radius: 10px;
}
.traveleerSide-ttransporcompanies-item-container-items1a{
    height: 70%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding-left: 2rem;
}
.traveleerSide-ttransporcompanies-item-container-items1a p{
    font-size: 12px;
    color: red;
}
.traveleerSide-ttransporcompanies-item-container-items1b{
    height: 80%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    padding-left: 2rem;
}
.traveleerSide-ttransporcompanies-item-container-items button{
    width:50%;
    height: 30%;
    background-color: red;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}
.ratingHolder{
    display: none;
}
.tansportCompanies-btnHolder2{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}





@media only screen and (min-width: 320px) and (max-width:768px) {
   .traveleerSide-ttransporcompanies-item-container-items1b{
    display: none;
   } 
   .traveleerSide-ttransporcompanies-item-container-items{
    padding-inline: 2%;
    height: 25vh;
   }
   .traveleerSide-ttransporcompanies-item-container-items1a{
    width: 70%;
    padding: 0;
    height:90%;
   }
   .traveleerSide-ttransporcompanies-item-container-items img{
    height: 60%;
    width: 28%;
    border-radius: 10px;
}
.traveleerSide-ttransporcompanies-item-container-items1a h3{
    font-size: 15px;
}
.traveleerSide-ttransporcompanies-item1a{
    width: 40%;
}
.tansportCompanies-btnHolder{
   width: 100%;
   height:20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.tansportCompanies-btnHolder button{
    width: 40%;
    height: 100%;
    color: white;
    background-color: red;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}
.ratingHolder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    display: flex;
}
}