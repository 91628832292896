.dashboard-footer{
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  gap: 0.8rem;
}
.dashboard-footer img{
  width: 15px;
  height: 15px;
}