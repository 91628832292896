.companyUpload-container{
  width: 68%;
  height: 100%;
  padding-bottom: 2rem;
}
.company-dash-toplayer{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.companyUpload-header{
  width: 100%;
  height: 9vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.204);
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  gap: 4rem;
}
.companyUpload-header-h4{
  cursor: pointer;
  padding-bottom: 0.5rem;
  border-radius: 2px 2px 2px 2px;
}
.companyUpload-header-h4-active{
  cursor: pointer;
  padding-bottom: 0.5rem;
  border-bottom: solid 4px red;
  border-radius: 2px 2px 2px 2px;
}
.companyUpload-mainContent{
  width: 100%;
  height: 130vh;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}
.companyUpload-mainContent-section1{
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.companyUpload-mainContent-item{
  width: 45%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.companyUpload-mainContent-item p{
  width: 100%;
}
.companyUpload-mainContent-item-imgHolder{
  width: 100%;
  height: 85%;
  border: solid 1px rgba(0, 0, 0, 0.246);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EEE8E8;
}
.companyUpload-mainContent-item-imgHolder img{
  width: 90%;
  height: 90%;
}
.company-upload-terminalContainer{
  padding-top: 2rem;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.company-upload-terminalpg1-input{
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}
.company-upload-terminalpg1-input input{
  width: 50%;
  height: 50%;
  border: solid 1px rgba(0, 0, 0, 0.222);
  border-radius: 5px;
  padding-left: 2rem;
 }
 .saveBtn2{
  width: 20%;
  height: 9%;
  background-color: #FF3131;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
 }
 .company-terminal2-container{
  width: 100%;
  height: 120vh;
  padding-top: 2rem;
 }
 .company-name2{
  width: 30%;
  height: 100%;
  padding-left: 2rem;
  display: flex;
  align-items: center;
 }
 .Company-terminal2{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
 }
 .company-date12{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
 }
 .company-terminalq-addBtn{
  width: 100%;
  height: 9vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
 }

 .company-terminalq-addBtn button{
  width: 18%;
  height: 70%;
  border: none;
  border-radius: 5px;
  background-color: red;
  color: white;

 }
 .addNew-terminal-Container{
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 2rem;
 }
.addNewterminal-section1{
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
}
.addNewterminal-section2{
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
}

 .addnewTermina-section1-item-holder{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }
 .addnewTermina-section1-item-holder input{
  width: 90%;
  height: 60%;
  border: solid 1px rgba(0, 0, 0, 0.203);
  border-radius: 5px;
 }
 .addnewTermina-section1-item-holder2{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }
 .addnewTermina-section1-item-holder2 input{
  width: 95%;
  height: 60%;
  border: solid 1px rgba(0, 0, 0, 0.203);
  border-radius: 5px;
 }
 .saveBtn4{
  width: 20%;
  height: 6%;
 }


 @media only screen and (min-width:320px) and (max-width:768px) {
  .companyUpload-container{
    width: 90%;
  }
  .companyUpload-header{
    width: 100%;
    padding-left: 1rem;
  }
  .companyUpload-mainContent-section1{
    width: 100%;
    height: max-content;
    min-height: 100vh;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  .companyUpload-mainContent-item{
    width: 100%;
  }

  .company-terminal2-container{
    width: 100%;
  }
  .company-terminalq-addBtn{
    width: 100%;
  }
  .company-terminalq-addBtn button{
    width: 40%;
  }
  .clientManagement-history-labels2{
    display: none;
  }
  .clientManagement-history-navigation{
    display: none;
  }
  .company-teminal-item{
    width: 100%;
    height: 25vh;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.416);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  .company-teminal-item1{
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px rgba(0, 0, 0, 0.416);
      align-items: flex-end;
      padding-inline: 1rem;
      justify-content: center;
  }
  .company-teminal-item1a{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }  
  .company-teminal-item2{
    height: 25%;
    width: 100;
    display: flex;
    align-items: center;
      border-bottom: solid 1px rgba(0, 0, 0, 0.416);
      padding-inline: 1rem;
      justify-content: space-between;
  }
  .company-upload-terminalpg1-input{
    width: 100%;
  }
  .company-upload-terminalpg1-input input{
    width: 100%;
  }
  .addnewTermina-section1-item-holder{
    width: 100%;
  }
  .addnewTermina-section1-item-holder input{
    width: 100%;
  }
  .addNewterminal-section2{
    width: 100%;
    flex-direction: column;
    height: 40%;
  }
  .addnewTermina-section1-item-holder2{
    width: 100%;
  }
  .addnewTermina-section1-item-holder2 input{
    width: 100%;
  }
  .companyVehicles-mainContainer{
    width: 90%;
    padding: 
    0;
  }
 }