.traveller-aboutUs{
  width: 100%;
  height: 100%;
}

.traveller-aboutUs-main{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.traveller-aboutUs-topSection{
  width: 100%;
  height: 40vh;
  color: white;
  background: url(../../images//contactImg.png);
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.traveller-aboutus-section2{
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-aboutus-section2-leftside{
  width: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.traveller-aboutus-section2-rightside{
  width: 35%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.traveller-aboutus-section2-rightside img{
  width: 100%;
  height: 90%;
}
.traveller-aboutus-section3{
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.traveller-aboutus-section3-leftside{
  width: 40%;
  height: 90%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.248);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.traveller-aboutus-section3-leftside img{
  width: 80%;
  height: 50%;
  background-color: burlywood;
  border-radius: 10px;
}
.tav-abo-sec3-title{
  width: 80%;
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.p-line-holder{
  display: flex;
  gap: 5px;
}
.p-line1{
  width: 20px;
  background-color: red;
  height: 2.5px;
  border-radius: 2.5px;
}
.p-line2{
  width: 15px;
  background-color: red;
  height: 2.5px;
  border-radius: 2.5px;
}
.p-line3{
  width: 7px;
  background-color: red;
  height: 2.5px;
  border-radius: 2.5px;
}
.abou-sec3-contentText{
  width: 80%;
  font-size: 12px;
  text-align: justify;
}
.traveller-about-section4{
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-about-section4-leftside{
  padding: 1rem;
  width: 25%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.traveller-about-section4-leftside h5{
  color: red;
}
.traveller-about-section4-rightside{
  width: 60%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.traveller-teamCard{
  width: 45%;
  height: 45%;
  background-color: #d9d9d9;
  border-radius: 5px;
  display: flex;
  gap:1rem;
  align-items: center;
  justify-content: space-around;
}
.traveller-teamCard img{
  width: 20%;
  height: 80%;
  border-radius: 50%;
}
.traveller-team{
  width: 60%;
}




@media only screen and (min-width:320px) and (max-width:576px) {
  .traveller-aboutUs-main{
    gap: 3rem;
  }
  .traveller-aboutUs-topSection{
    height: 30vh;
  }
  .traveller-aboutus-section2{
    flex-direction: column-reverse;
    height: 120vh;
  }
  .traveller-aboutus-section2-leftside{
    width: 90%;
  }
  .traveller-aboutus-section2-leftside p{
    text-align: justify;
  }
  .traveller-aboutus-section2-rightside{
    width: 90%;
    height: 30%;
  }
  .traveller-aboutus-section3{
    flex-direction: column;
    height: 100vh;
  }
  .traveller-aboutus-section3-leftside{
    width: 90%;
    height: 48%;
  }
  .traveller-about-section4{
    flex-direction: column;
    height: 70vh;
  }
  .traveller-about-section4-leftside{
    width: 90%;
    height: 20%;
  }
  .traveller-about-section4-rightside{
    width: 90%;
    justify-content: center;
  }
  .traveller-teamCard{
    width: 90%;
    height: 30%;
  }
}