.DashboardHeader-main-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  padding-inline: 2rem;

}
.dashboard-header-notification{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.dashboard-header-notification img{
  width: 25px;
  height: 25px;
}
