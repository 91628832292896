.travellerSide-homepage-container{
  /* height: 300vh; */
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  gap: 2rem;
}
.traveller-non-heroHolder{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.traveller-homepage-hero{
  width: 100%;
  height: 60vh;
  background-image:url("../../../TravellerSide/images/redBckgrnd.png"); 
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: center;
  gap: 1rem;
}
.traveller-homepage-hero-item{
  width: 90%;
  height: 20%;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.traveller-homepage-hero-item select{
  width: 10%;
  height: 50%;
  border-radius: 20px;
  background-color: transparent;
  border: solid 1px white;
  color: white;
}
 option{
  color: red;
  padding-right: 10px;
  background: transparent;
  border: none;
}
.traveller-heropage-tripSearch{
  height: 50%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  
}
.traveller-homepage-tripsearch-item{
  width: 20%;
  height: 30%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-inline: 15px;
  color: black;

}
.card1-imgHolder img{
  height: 90%;
  width: 70%;
}

.card1-Holder{
  width: 25%;
  height: 90%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.411);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  border-radius: 15px;
  padding-left: 1rem;
  align-items: center;
}
.card1-details{
  width: 90%;
  height: 30%;
}
.card1-imgHolder{
  height: 50%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-homepage-tripsearch-item-btnHolder{
  display: none;
}
.traveller-homepage-tripsearch-item3{
  width: 20%;
  height: 30%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  color: black;
  padding-inline: 15px;
}
.traveller-homepage-tripsearch-item input{
  border: none;
  padding-left: 1rem;
}
.traveller-homepage-tripsearch-item2{
  height: 30%;
  width: 5%;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
select:active{
  border: none;
}
.traveller-homepage-tripsearch-btn{
  width: 15%;
  height: 30%;
  border-radius: 5px;
  color: red;
  border: none;
  background-color: white;
}
.traveller-homepage-section2{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
 
}
.traveller-homepage-section2-redline{
  width: 5%;
  height: 5px;
  border-radius: 5px;
  background-color: red;
}
.traveller-homepage-section2-top{
 width: 90%;
 height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.6rem;
}
.traveller-homepage-section2-cardholder{
  width: 90%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.traveller-homepage-section2-btn{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.traveller-homepage-section2-btn button{
  width: 10%;
  height: 60%;
  border: none;
  background-color: red;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.traveller-homepage-section3{
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.traveller-homepage-section3-main{
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-homepage-section3-leftMain{
  width: 40%;
  height: 90%;
}
.traveller-homepage-section3-rightMain{
  width: 40%;
  height: 90%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: column;
  padding-top: 3rem;
}
.traveller-homepage-section3-leftMain img{
  width: 90%;
}
.traveller-homepage-section3-rightMain span{
  color: red;
}
.traveller-homepage-section3-rightMain p{
  font-size: 12px;
  line-height: 20px;
}
.traveller-homepage-section3-rightMain span{
  width: 100%;
}
.traveller-homepage-section4{
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-homepage-section4-main{
  width: 90%;
  height: 90%;
  background-color: red;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  flex-direction: column;
}

.traveller-homepage-section4-main button{
  background-color: white;
  border: none;
  color: red;
  padding-inline: 20px;
  padding-block: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.search-result-holder{
  width: 100%;
  min-height: 100vh;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 2rem;
}
.search-result-item{
  width: 90%;
  height: 22vh;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.236);
  background-color: #F6F6F6;
}
.search-result-item-top{
  height: 30%;
  width: 100%;
  border-radius: 15px 15px 0 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 4rem;
}
.search-result-item-top img{
  width: 5%;
  height: 90%;
}
.search-result-item-mid{
  height: 40%;
  width: 100%;
  border-top: solid 1px rgba(0, 0, 0, 0.257);
  padding-left: 4rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.257);
  display: flex;
  align-items: center;
}
.search-result-item-btm{
  height: 30%;
  padding-left: 4rem;
  padding-right: 4rem;
  width: 100%;
  border-radius: 0 0 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-result-item-btm p{
  color: red;
}
.search-result-item-midA{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.search-result-item-midC{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}
.search-result-item-midB{
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newDot{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}
.userSearch-details-holder{
  width: 100%;
  height: max-content;
  padding-bottom: 2rem;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userSearch-details-item1{
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.userSearch-details-item2{
  width: 90%;
  height: 60vh;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.255);
  border-radius: 15px;
}

.userSearch-details-item2a{
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.userSearch-details-item2a1{
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.userSearch-details-item2a1a{
  height:35px;
  width: 35px;
  background-color: #FF725E;
  border-radius: 50%;
}
.userSearch-details-item2a1b{
  height:35px;
  width: 35px;
  border-radius: 50%;
  border: solid 2px black;
}
.userSearch-details-item2a1c{
  height:35px;
  width: 35px;
  background-color: red;
  border-radius: 50%;
}
.userSearch-details-item2b{
  width: 100%;
  height: 8vh;
  border-top: solid 1px rgba(0, 0, 0, 0.200);
  border-bottom: solid 1px rgba(0, 0, 0, 0.200);
  display: flex;
  align-items: center;
  justify-content: center;
}
.userSearch-details-item2c{
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 4rem;
}
.userSearch-details-item2c1{
  width: 30%;
  height: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.userSearch-details-item2c1a{
  width: 26%;
  height: 26%;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.userSearch-details-item2c1a img{
  width: 20px;
  height: 20px;
}
.userSearch-details-item2c1b{
  width: 26%;
  height: 26%;
  border-radius: 5px;
  font-size: 25px;
  font-weight: 600;
  border: solid 1px black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userSearch-details-item2c1c{
  width: 26%;
  height: 26%;
  border-radius: 5px;
  font-size: 25px;
  font-weight: 600;
  border: none;
  background-color: #FF725E;
  display: flex;
  align-items: center;
  justify-content: center;
}.userSearch-details-item2c1d{
  width: 26%;
  height: 26%;
  border-radius: 5px;
  font-size: 25px;
  font-weight: 600;
  border: none;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userSearch-details-item3{
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.userSearch-details-item3 button{
  width: 10%;
  height: 70%;
  border: none;
  border-radius: 50px;
  background-color: red;
  color: white;
  cursor: pointer;
}
.luggageInfo-mainHolder{
  width: 100%;
  height: max-content;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.luggageInfo-mainHolder-sec1{
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.luggageInfo-mainHolder-sec2{
  width: 90%;
  height: 15vh;
  border: solid 1px red;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-size: 15px;
  flex-direction: column;
  gap: 1rem;
}
.luggageInfo-mainHolder-sec2 h6{
  width: 80%;
  /* height: 20%; */
}
.luggageInfo-mainHolder-sec2 p{
  width: 80%;
  /* height: 20%; */
}
.luggageInfo-mainHolder-sec3{
  width: 90%;
  height: 10vh;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.luggageInfo-mainHolder-sec3 select{
  width: 50%;
  height: 80%;
  background: transparent;
  border: solid 1px rgba(0, 0, 0, 0.248);
  padding-inline: 20px;
}
.luggageInfo-mainHolder-sec4{
  width: 90%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.luggageInfo-mainHolder-sec4 textarea{
  width: 70%;
  height: 70%;
  background: transparent;
  border: solid 1px rgba(0, 0, 0, 0.248);
  padding: 10px 20px 10px 20px;
}
.luggageInfo-mainHolder-sec5{
  width: 90%;
  height: 40vh;
}
.luggageInfo-mainHolder-sec5-imgHlder{
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.luggageInfo-mainHolder-sec5-imgHlder img{
  width: 30%;
  height: 90%;
  display: flex;
  align-items: center;
  background-color: #FF725E;
  justify-content: center;
}
.luggageInfo-mainHolder-sec6{
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.luggageInfo-mainHolder-sec6 button{
  width: 10%;
  height: 70%;
  border: none;
  border-radius: 10px;
  background-color: red;
  color: white;
  cursor: pointer;
}
.bookingDetails-holder{
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}
.bookingDetails-holder-sec1{
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.bookingDetails-holder-sec2{
  width: 90%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  grid-area: 0.5rem;
}
.bookingDetails-holder-sec2 h5{
  width: 100%;
}
.bookingDetails-holder-sec2a{
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-between;
}
.bookingDetails-holder-sec2a1-left{
  width: 48%;
  height: 90%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.252);
  border: none;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bookingDetails-holder-sec2a1-right{
  width: 48%;
  height: 100%;
  border: none;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bookingDetails-holder-sec2a1-lefta{
  width: 95%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bookingDetails-holder-sec2a1-leftb{
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px rgba(0, 0, 0, 0.252);
  border-bottom: solid 1px rgba(0, 0, 0, 0.252);
  padding-inline: 5%;
  font-size: 14px;
}
.bookingDetails-holder-sec2a1-leftc{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: solid 1px rgba(0, 0, 0, 0.252);
}
.bookingDetails-holder-sec2a1-leftd{
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: red;
  padding-right: 5%;
  gap: 1rem;
}
.bookingDetails-holder-sec2a1-lefta-item1{
  width: 40%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}
.bookingDetails-holder-sec2a1-lefta-item2{
  width: 40%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookingDetails-holder-sec2a1-lefta-item1 img{
  width: 40%;
  height: 60%;
}
.bookingDetails-holder-sec2a1-leftb1{
  width: 30%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bookingDetails-holder-sec2a1-leftb-dot{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  border: none;
}
.bookingDetails-holder-sec2a1-leftc1{
  width: 90%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bookingDetails-holder-sec2a1-leftc1 label{
  font-size: 13px;
  color: rgba(0, 0, 0, 0.412);
}
.bookingDetails-holder-sec2a1-right-top{
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.253);
  border-radius: 15px;
  align-items: center;
  justify-content: center;
}
.bookingDetails-holder-sec2a1-right-top-1{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.255);
  padding-inline: 5%;
}
.bookingDetails-holder-sec2a1-right-top-2{
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 1px rgba(0, 0, 0, 0.255);
  padding-inline: 5%;
  justify-content: space-around;
}
.bookingDetails-holder-sec2a1-right-top-3{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  padding-inline: 5%;
}
.bookingDetails-holder-sec2a1-right-top-1a{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
}
.bookingDetails-holder-sec2a1-right-top-1aMain{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.bookingDetails-holder-sec2a1-right-top-1aMain p{
    font-size: 14px;
    font-weight: 600;
}
.bookingDetails-holder-sec2a1-right-top-1aMain h3{
  color: red;
}
.bookingDetails-holder-sec2a1-right-btm{
  width: 100%;
  height: 12%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bookingDetails-holder-sec2a1-right-btm button{
  width: 20%;
  height: 70%;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer; 
}
.bookingPayment-mainContainer{
  width: 100%;
  height: max-content;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-block: 1rem;
}
.bookingPayment-mainContainer-top{
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.bookingPayment-mainContainer-sec2{
  width: 90%;
  height: 20vh;
}
.bookingPayment-mainContainer-sec2a{
  width: 60%;
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px;
  align-items: center;
  gap: 2rem;
}
.bookingPayment-mainContainer-sec2a1{
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
}
.bookingPayment-mainContainer-sec2a h5{
  width: 80%;
}
.bookingPayment-mainContainer-sec2aPrice{
  color: red;
}
.bookingPayment-mainContainer-sec3{
  width: 90%;
  min-height: 40vh;
  height: max-content;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 0.5rem;
}
.bookingPayment-mainContainer-sec3a{
  width: 50%;
  display: flex;
  height: 10vh;
  justify-content: space-around;
  align-items: center;
}

.bookingPayment-mainContainer-sec3a1{
  width: 40%;
  height: 60%;
  border: solid 1px rgba(0, 0, 0, 0.256);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 5%;
  gap: 10%;
}
.bookingPayment-mainContainer-sec3b{
  width: 100%;
  display: flex;
  height: 10vh;
  border: none;
  flex-direction: column;
  justify-content: space-around;
}
.bookingPayment-mainContainer-sec3c{
  width: 100%;
  display: flex;
  height: 10vh;
  border: none;
  flex-direction: column;
  justify-content: space-between;
}
.bookingPayment-mainContainer-sec3b1{
  width: 50%;
  display: flex;
  height: 60%;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.262);
  padding-left: 1rem;
  align-items: center;
}
.bookingPayment-mainContainer-sec3b1 input{
  border: none;
  height: 80%;
  background-color: transparent;
  padding-left: 1rem;
}
.bookingPayment-mainContainer-sec3c1{
  width:50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.bookingPayment-mainContainer-sec3c1a{
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
}
.bookingPayment-mainContainer-sec3b9{
  height: 60%;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.262);
  padding-left: 1rem;
  align-items: center;
  display: flex;
}
.bookingPayment-mainContainer-sec3b9 input{
  border: none;
  height: 80%;
  background-color: transparent;
  padding-left: 1rem;
}
.bookingPayment-mainContainer-sec4{
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
}
.bookingPayment-mainContainer-sec4 button{
  width: 10%;
  height: 60%;
  border-radius: 25px;
  color: white;
  background-color: red;
  border: none;
  cursor: pointer;
}






@media only screen and (min-width:320px) and (max-width:576px) {
  
  .search-result-holder{
    height: max-content;
    min-height: 100vh;
    padding-block: 2rem;
    margin-bottom: 5rem;
  }
  .traveller-homepage-hero-item{
      align-items: center;
      justify-content: center;
  }
  .traveller-homepage-hero-item select{
    width: 40%;
  }
  .traveller-heropage-tripSearch{
    flex-wrap: wrap;
    height: 40%;
  }

  .traveller-homepage-tripsearch-item{
    width: 40%;
    height: 25%;
  }
  .traveller-homepage-tripsearch-item input{
    border: none;
    /* padding-left: 1rem; */
    height: 60%;
    width: 60%;
  }
  .traveller-homepage-tripsearch-item2{
    height: 25%;
    width: 15%;
  }
  .traveller-homepage-tripsearch-item2 img{
    width: 40%;
  }
  .traveller-homepage-tripsearch-item3{
    height: 25%;
    width: 40%;
  }
  .traveller-homepage-tripsearch-item3 p{
    /* width: 50%; */
    font-size: 10px;
  }
  .traveller-heropage-tripSearch button{
    display: none;
  }
  .traveller-homepage-tripsearch-item-btnHolder{
    display: flex;
    height: 12%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .traveller-homepage-tripsearch-item-btnHolder button{
    height: 90%;
    width: 20%;
    border: none;
    background-color: white;
    color: red;
    border-radius: 5px;
  }
.card1-Holder{
  width: 90%;
}
  .traveller-homepage-section2-cardholder{
  flex-direction: column;
  gap: 1rem;
  }
  .traveller-homepage-section2{
    height: 200vh;
    justify-content: space-around;
  }
  .traveller-homepage-section2-top{
    height: 10vh;
  }
  .traveller-homepage-section2-btn button{
    width: 30%;
    height: 30%;
  }
  .traveller-homepage-section2-cardholder{
    height: 160vh;
  }
  .traveller-homepage-section3-main{
    flex-direction: column;
    justify-content: center;
    
  }
  .traveller-homepage-section3-leftMain{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .traveller-homepage-section3-rightMain{
    width: 90%;
    align-items: start;
  }
  .traveller-homepage-section4{
    height: 20vh;
  }
  .traveller-homepage-section4 h1{
    font-size: 20px;
  }
  .search-result-holder{
    height: 60vh;
  }
  .search-result-item-top{
    padding-left: 0.5rem;
  }
  .search-result-item-mid{
    padding-inline: 0.5rem;
    justify-content: space-between;
  }
  .search-result-item-btm{
    padding-left: 0.5rem;
  }
  .search-result-item-top img{
    width: 15%;
  }
  .search-result-item-midA{
    width: 30%;
    gap: 0.5rem;
  }
  .search-result-item-midC{
    width: 30%;
    gap: 0.5rem;
  }
  .search-result-item-midB{
    width: 30%;
    gap: 0.5rem;
  }
  .search-result-item-btm{
    padding-inline: 0.5rem;
  }
  .userSearch-details-item2a{
    gap: 0.5px;
    justify-content: space-around;
  }
  .userSearch-details-item2a1{
    width: 30%;
    gap: 0.3rem;
  }
  .userSearch-details-item2a1a{
    width: 20px;
    height: 20px;
  }
  .userSearch-details-item2a1b{
    width: 20px;
    height: 20px;
  }
  .userSearch-details-item2a1c{
    width: 20px;
    height: 20px;
  }
  .userSearch-details-item2c{
    padding-inline: 0.3rem;
  }
  .userSearch-details-item2c1{
    width: 48%;
  }
  .userSearch-details-item2c1a{
    flex-direction: column;
  }
  .userSearch-details-item3 button{
    width: 30%;
    height: 60%;
    border-radius: 30px;
    cursor: pointer;
  }
  .luggageInfo-mainHolder-sec2{
    height: 20vh;
    gap: 0.5rem;
  }
  .luggageInfo-mainHolder-sec2 p{
    text-justify: distribute;
    text-align: justify;
  }
  .luggageInfo-mainHolder-sec3 select{
    width: 100%;
  }
  .luggageInfo-mainHolder-sec4 textarea{
    width: 100%;
  }
  .luggageInfo-mainHolder-sec5{
    height: 20vh;
  }
  .luggageInfo-mainHolder-sec5-imgHlder{
    width: 100%;
  }
  .luggageInfo-mainHolder-sec5-imgHlder img{
    width: 45%;
    height: 80%;
  }
  .luggageInfo-mainHolder-sec6 button{
    width: 30%;
  }
  .bookingDetails-holder-sec2{
    height: 120vh;
  }
  .bookingDetails-holder-sec2a{
    flex-direction: column;
    gap: 1.5rem;
  }
  .bookingDetails-holder-sec2a1-left{
    width: 100%;
    height: 60%;
    font-size: 14px;
  }
  .bookingDetails-holder-sec2a1-right{
    width: 100%;
    height: 40%;

  }
  .bookingDetails-title{
    display: none;
  }
  .bookingDetails-holder-sec2a1-leftc1 label{
    font-size: 14px;
  }
  .bookingDetails-holder-sec2a1-left h3{
    font-size: 14px;
  }
  .bookingDetails-holder-sec2a1-leftc1 h6{
    font-size: 14px;
  }
  .bookingDetails-holder-sec2a1-right-btm button{
    width: 35%;
    height: 100%;
    border-radius: 30px;
  }
  .bookingPayment-mainContainer-sec2a{
    width: 100%;
    gap: 1rem;
    height: 15vh;
  }
  .bookingPayment-mainContainer-sec3a{
    width: 100%;
    justify-content: space-between;
  }
  .bookingPayment-mainContainer-sec3a1{
    width: 47%;
  }
  .bookingPayment-mainContainer-sec3b1{
    width: 100%;
  }
  .bookingPayment-mainContainer-sec3c1{
    width: 100%;
  }
  .bookingPayment-mainContainer-sec4 button{
    width: 30%;
  }
  .bookingPayment-mainContainer-sec3b9{
    padding-left: 10px;
  }
  .bookingPayment-mainContainer-sec3b9 input{
    width: 70%;
  }
}