.header-traveller-main{
  width: 100%;
  height: 20vh;
  box-shadow:0 0 5px rgba(0, 0, 0, 0.272);
}
.Traveller-header-top{
  width: 100%;
  height: 40%;
  background-color: black;
  padding-inline: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Traveller-header-top-item{
  color: white;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.Traveller-header-top-item p{
  font-size: 12px;
  font-weight: 600;
}
.traveller-button-holder{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.traveller-button-holder button{
  width: 20%;
  height: 70%;
  border-radius: 10px;
  border: none;
  color: red;
  font-weight: bolder;
  cursor: pointer;
  font-size: 12px;
}
.traveller-header-bottom{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.traveller-header-bottom-item1{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
justify-content: center;
}
.traveller-header-bottom-item1 img{
  height: 80%;
}
.traveller-header-bottom-item2{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.traveller-header-bottom-item2 p{
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.activeP{
  color: red;
}
.traveller-header-bottom-item3{
  width: 50%;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 1.5rem;
  justify-content: flex-end;
  padding-right: 4rem;
}
.userProfile-option-holder{
  width: 10%;
  height: 15vh;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  right: 12%;
  z-index: 9999999999999999999999999999;
}
.profileOptions{
  width: 10%;
  height: 20vh;
  background-color: white;
  position: relative;
  /* top: 15vh; */
  right: -78%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.241);
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.profileOptions p{
  cursor: pointer;
  font-size: 12px;

}
.profileOptions button{
  cursor: pointer;
  border: none;
  border-top: solid 1px black;
  width: 100%;
  height: 20%;
  background-color: transparent;
}

.travelle-header-sec3Items2{
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.travelle-header-sec3Items2-img{
  width: 20%;
  height: 60%;
  border-radius: 50%;
}
.traveller-header-1{
  padding-inline: 20px;
  padding-block: 8px;
  font-size: 12px;
  border: none;
  cursor: pointer;
}

.traveller-header-button-2{
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
}
.traveller-header-button-3{
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: red;
  color: white;
}
.traveller-header-bottom-item4{
  display: none;
}
.menuItems-lists-Holder{
  display: none;
}
.searchIcon{
  width: 20px;
  height: 20px;
}




@media only screen and (min-width:320px) and (max-width:576px) {

  .header-traveller-main{
    height: 15vh;
  }
  
  .Traveller-header-top{
    display: none;
  }
  .traveller-header-bottom{
    justify-content: center;
  }
  .traveller-header-bottom-item1{
    width: 40%;
    padding: 0;
  }
  .traveller-header-bottom-item2{
    display: none;
  }
  .traveller-header-bottom-item3{
    display: none;
  }
  .traveller-header-bottom-item4{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .traveller-header-bottom-item4 img{
    width: 20%;
  }
  .menuItems-lists-Holder{
    display: flex;
    flex-direction: column;
    background-color: aqua;
    height: 70vh;
    width: 50%;
    z-index: 99;
    position: relative;
    left: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.209);
  }
  .menuItems-lists-Holder-topSec{
    width: 100%;
    height: 35%;
    background-color: black;
    color: wheat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
  }
  .menuItems-lists-Holder-btmSec{
    width: 100%;
    height: 65%;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .menuItems-lists-Holder-topSec-item{
    width: 80%;
    height: 20%;
    display: flex;
    align-items: center;
    font-size: 10px;
    gap: 0.5rem;
  }
  .menuItems-lists-Holder-topSec-item img{
    width: 10px;
    height: 10px;
  }
  .menuItems-lists-Holder-topSec-item-button-holder{
    width: 80%;
    height: 20%;
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 0.5rem;
  }
  .menuItems-lists-Holder-topSec-item-button-holder button{
    width: 60%;
    height: 80%;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: black;
    font-size: 12px;

  }
  .menuItems-lists-Holder-btmSec-item1{
    width: 80%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menuItems-lists-Holder-btmSec-item2{
    width: 80%;
    height: 10%;
    display: flex;
    align-items: center;
    font-size: 12px;
  } 
  .menuItems-lists-Holder-btmSec-item2-active{
    width: 80%;
    height: 10%;
    display: flex;
    align-items: center;
    color: red;
    font-size: 12px;
  }
  .menuItems-lists-Holder-btmSec-item2-p{
    cursor: pointer;
  }
  .menuItems-lists-Holder-btmSec-srchBtn{
    width: 80%;
    height: 95%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.264);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
  }
  .menuItems-lists-Holder-btmSec-srchBtn img{
    width: 10px;
    height: 10px;
  }
  .menuItems-lists-Holder-btmSec-srchBtn input{
    border: none;
    background-color: transparent;
    width: 80%;
  }
  .postYour-company{
    width: 80%;
    height: 10%;
  }
  .postYour-company button{
    width: 80%;
    background-color: #5a5a5a06;
    height: 90%;
    border: none;
    border-radius: 5px;
    font-size: 12px;
  }
  .menuItems-loginBtn-holder{
    width: 80%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menuItems-loginBtn-holder1{
    width: 45%;
    height: 85%;
    background-color: black;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    border: none;
  }
  .menuItems-loginBtn-holder12{
    background-color: red;
    width: 45%;
    height: 85%;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    border: none;
  }
  /* .traveller-header-1{
    padding-inline: 1rem;
  } */
  .menuItems-lists-Holder-btmSec-item3{
    width: 80%;
  }
  .menuItems-lists-Holder-btmSec-item3a{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
  }
  .menuItems-lists-Holder-btmSec-item3 button{
    width: 100%;
    height: 35px;
    border-radius: 10px;
    border: none;
  }
  .menuItems-lists-Holder-btmSec-item3a button{
    width: 45%;
    height: 35px;
    border-radius: 10px;
    border: none;
  }
}