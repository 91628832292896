.companyside-dashboard-container{
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.mobileSideBar{
  display: none;
}
.comapnyside-sidebar-conatiner{
  width: 28%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-dash-toplayer{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.company-sidebar-holder{
  width: 70%;
  min-height: 60%;
  height: max-content;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.204);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  color: rgba(0, 0, 0, 0.652);
  padding-bottom: 2rem;
}
.company-sidebar-holder img{
  width: 60%;
  height: 20%;
}
.company-sidebar-item{
  width: 80%;
  height: 5vh;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 2rem;
  cursor: pointer;
}

.company-sidebar-item img{
  height: 20px;
  width: 20px;
}
.company-sidebar-item p{
  font-weight: 600;
}
.company-sidebar-item-active{
  width: 80%;
  height: 12%;
  background-color: #FFA5A5;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.company-sidebar-item-active img{
  height: 20px;
  width: 20px;
}
.company-sidebar-item-active p{
  font-weight: 600;
}




@media screen and (min-width:320px) and (max-width:768px) {
  
  .mobileSideBar{
    width: 50%;
    height: max-content;
    position: absolute;
    top: -10vh;
    right: 0;
    display: flex;
    flex-direction: column;
  }
  .comapnyside-sidebar-conatiner{
    display: none;
  }
  .companyside-dashboard-container{
    position: relative;
    justify-content: center;
  }
  .mobileSideBar-top{
    width: 100%;
    height: 10vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
    background-color: white;
  }
  .mobileSideBar-bottom{
    height: 70vh;
    background-color: white

  }
  .company-sidebar-holder2am{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.204);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    color: rgba(0, 0, 0, 0.652);
    padding-top: 1rem;
  }
}