.sidebar-main{
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  justify-content: space-around;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}
.sidebar-main2{
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  justify-content: space-around;
}

.sidebar-menu-list{
  width: 80%;
  height: 10%;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  gap: 1.2rem;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.548);
  cursor: pointer;
}
.sidebar-menu-list-active{
  width: 80%;
  height: 10%;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  gap: 1.2rem;
  font-weight: 550;
  background-color: #FFCBCB;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}
.sidebar-menu-list-active img{
  width: 20px;
  height: 20px;
}
.sidebar-menu-list img{
  width: 20px;
  height: 20px;
}
.settings-dropdown{
  width: 80%;
  height: 15%;
  margin-top: -1.9rem;
  

  
}
.settings-dropdown2{
  width: 100%;
  height: 100%;
  background-color: #FFCBCB;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  
}
.settings-dropdown-items{
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 1.5rem;
  height: 30%;
  font-weight: 400;
  font-size: 1rem;color: rgba(0, 0, 0, 0.70);
  cursor: pointer;

}
.settings-dropdown-items-active{
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ff313197;
  padding-left: 1.5rem;
  height: 30%;
  font-weight: 400;
  font-size: 1rem;color: rgba(0, 0, 0, 0.70);
  cursor: pointer;

}
.dot{
  width: 10px;
  height: 10px;
  background-color: #FF3131;
  border-radius: 50%;
}