.traveller-ContactUs-container{
  width: 100%;
  height: max-content;
}
.traveller-contactUs-main{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-contactUs-leftSec{
  width: 50%;
  height: 95%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
}
.traveller-contact-input{
  width: 90%;
  height: 7%;
  border: solid 1px rgba(0, 0, 0, 0.168);
  padding: 1rem;
  border-radius: 5px;
}
.inputmessage{
  height: 30%;
  width: 90%;
  border: solid 1px rgba(0, 0, 0, 0.168);
  padding: 1rem;
  border-radius: 5px;
}
.traveller-contactUs-rightSec{
  width: 40%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.traveller-contactUs-leftSec h5{
  color: red;
}
.traveller-contactUs-leftSec button{
  width: 40%;
  border-radius: 5px;
  height: 10%;
  background-color: red;
  border: none;
  font-size: 18px;
  color: white;
  cursor: pointer;
}
.traveller-contactUs-leftSec label{
padding-left: 10px;
font-size: 12px;
}
.traveller-contactUs-leftSec span{
  color: rgba(0, 255, 255, 0.485);
}
.traveller-contactUs-rightSec img{
  height: 60%;
  width: 70%;
}
.traveller-contactUs-rightSec-sec2{
  height: 40%;
  width: 65%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.traveller-contactUs-rightSec-sec2-item{
  width: 100%;
  height: 28.3%;
  background-color: #F6F6F6;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.traveller-contactUs-rightSec-sec2-item-leftside{
  width: 15%;
  height: 80%;
  border-radius: 50%;
  background-color: #F8CFCF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-contactUs-rightSec-sec2-item-rightside{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 70%;
  height: 80%;
}
.traveller-contactUs-rightSec-sec2-item-leftside img{  
  height: 20px;
  width: 20px;
}
.traveller-contactUs-rightSec-sec2-item-rightside p{
  font-size: 12px;
}

@media only screen and (min-width:320px) and (max-width:576px) {
  .traveller-contactUs-main{
    flex-direction: column-reverse;
    height: max-content;
    gap: 2rem;
  }
  .traveller-contactUs-rightSec{
    width: 95%;
    height: 80vh;
  }
  .traveller-contactUs-rightSec img{
    width: 80%;
  }
  .traveller-contactUs-rightSec-sec2{
    width: 80%;
  }
  .traveller-contactUs-leftSec{
    width: 80%;
    height: 100vh;
    align-items: center;
  }
  .traveller-contactUs-leftSec button{
    height: 7.5%;
  }
}