.Dashcontent-container{
  height: max-content;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  padding-top: 0.5rem;
}
.dashcontent-section1{
  width: 90%;
  height: 8vh;
  padding-right: 2rem;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  gap: 1.2rem;

}
.dashcontent-section1 img{
  width: 20px;
  height: 20px;
}
.dashcontent-section1 p{
  font-weight: bold;
}

.dashontent-card-holder{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
}
.dashontent-card{
  width: 45%;
  height: 100%;
  padding-top: 1.5rem;
  border-radius: 5px;
  background-color: #FF3131;
  color: white;
}
.dashontent-card img{
  width: 30px;
  height: 30px;
}
.dashontent-card2 img{
  width: 30px;
  height: 30px;
}
.dashontent-card2{
  width: 45%;
  height: 100%;
  border-radius: 5px;
  padding-top: 1.5rem;
  background-color: black;
  color: white;
}
.dashontent-card-p{
  width: 90%;
  height: 30%;
  display: flex;
  align-items: center;
  padding-inline: 2rem;
  font-size: large;
  font-weight: 600;
  padding-top: 1.8rem;

  
}
.dashontent-card-sec1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 2rem;
}
.dashcontent-recent-activity-tag{
  padding-top: 1.5rem;
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}
.dashcontent-see-all{
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: flex-end;
  gap: 0.8rem;
  font-weight: 600;
}
.dashcontent-see-all img{
  height: 20px;
  width: 20px;
}
.dashontent-header{
  width: 100%;
  display: flex;
  font-size: 0.8rem;
  height: 10%;
}
.recent-activity-container{
  width: 100%;
  height: max-content;
  min-height: 40vh;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 0.8rem;
  justify-content: center;
  padding-top: 1rem;
  background-color: green;
}
.footer{
  width: 100%;
  height: 15%;
  padding-top: 2rem;
}