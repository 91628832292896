.dashboard-main-container{
  width: 100%;
  height: max-content;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.dashboard-sidebar-holder{
  width: 25%;
  height: 100vh;
  display: flex;
  padding-top: 2.5rem;
  /* justify-content: center; */
}
.dashboard-content-holder{
  width: 65%;
  height: max-content;
  min-height: 100vh;
}
