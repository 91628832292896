.companyside-main-content{
  width: 68%;
  height: 150vh;
}
.company-dash-toplayer{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.company-dash-toplayer button{
  width: 15%;
  height: 60%;
  border: none;
  border-radius: 15px;
  background-color: #FF3131;
  color: white;
}
.companyside-main-container{
  width: 100%;
  height: 140vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.company-profile-logo{
  border-radius: 50%;
}
.companydash-userProfile-section1{
  width: 100%;
  height: 32%;
}
.companydash-userProfile-section3{
  width: 100%;
  height: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.companydash-userProfile-section1Main{
  width: 50%;
  height: 100%;
  border: solid 2px rgba(0, 0, 0, 0.300);
  border-radius: 10px;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.companydash-userProfile-section1Main-row1{
  width: 100%;
  height: 35%;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.companydash-userProfile-section1Main-row1 img{
  width: 75px;
  height: 75px;
}
.companySide-userProfile-contactHolder{
  display: flex;
  gap: 1rem;
  
}
.companySide-userProfile-contactHolder img{
  width: 20px;
  height: 20px;
}
.companydash-userprofile-aboutUsHolder{
  width: 100%;
  height: 100%;
  border: solid 2px rgba(0, 0, 0, 0.300);
  border-radius: 10px;
  padding-left: 2rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
}
.companySide-aboutUS-content{
  width: 90%;
  height: 70%;
}
.companydash-userprofile-lastsection-header{
  width: 100%;
  height: 19%;
  background-color: #FFA5A5;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  font-size: 1.3rem;
  font-weight: 700;
}
.companydash-userprofile-lastsection-item-main{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.companydash-userprofile-lastsection-item{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  gap: 1rem;

}

.comapnydash-userprofile-socialHandle-input{
  width: 90%;
  height: 55%;
  border: solid 1px rgba(0, 0, 0, 0.212);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comapnydash-userprofile-socialHandle-input input{
  width: 80%;
  height: 100%;
  border: none;
  padding-left: 1rem;
}
.company-editProfile-topsection{
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.company-editProfile-topsection img{
  width: 70px;
  height: 70px;
}
.company-editprofile-topsection-inputfield{
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company-editprofile-topsection-inputfield2{
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company-editprofile-topsection-inputfield-item{
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.company-editprofile-topsection-inputfield-item input{
  width: 100%;
  height: 60%;
  border: solid 1px rgba(0, 0, 0, 0.204);
  background: transparent;
  border-radius: 5px;
  padding-left: 2rem;

}
.company-editProfile-secondSection{
  width: 100%;
  height: 23%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.company-editProfile-secondSection input{
  width: 100%;
  height: 70%;
  border: solid 1px rgba(0, 0, 0, 0.204);
  border-radius: 5px;
  padding: 2rem;
  
  
}
.company-editProfile-lastSection{
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.company-editprofile-lasction-item{
  width: 100%;
  height: 35%;
  display: flex;
}
.saveBtn{
  width: 20%;
  height: 5%;
  background-color: #FF3131;
  border: none;
  color: white;
  border-radius: 5px;
}



@media only screen and (min-width:320px) and (max-width:768px) {
  .companyside-main-content{
    width: 90%;
    height: max-content;
  }
  .company-dash-toplayer button{
    width: 30%;
  }
  .companydash-userProfile-section1Main{
    width: 100%;
    height: 40vh;
  }
  .companyside-main-container{
    height: max-content;
    min-height: 140vh;
    gap: 2rem;
  }
  .companydash-userprofile-aboutUsHolder{
    height: 75vh;
  }
  .companydash-userProfile-section3{
   min-height: 40vh;
  }
  .companydash-userprofile-lastsection-header{
    height: 6vh;
    border-radius: 20px;
    margin-bottom: 1rem;
  }
  .companydash-userprofile-lastsection-item{
    width: 100%;
    height: 15vh;
  }
.companydash-userprofile-lastsection-item-main{
    flex-direction: column;
    height: 60%;

}
.comapnydash-userprofile-socialHandle-input{
  width: 100%;
}
.company-editProfile-topsection h4{
  display: none;
}
.company-editProfile-topsection{
  height: max-content;
  min-height: 10vh;
}
.company-editProfile-topsection{
  height: 50vh;
  align-items: center;
}
.company-editProfile-topsection img{
  width: 120px;
  height: 120px;
}
.company-editprofile-topsection-inputfield-item{
  width: 100%;
}
.company-editprofile-topsection-inputfield2{
  flex-direction: column;
  height: 50%;
}
.company-editProfile-lastSection{
  flex-direction: column;
}
.company-editprofile-lasction-item{
  flex-direction: column;
}
.saveBtn{
  width: 40%;
  height: 5vh;
}
.companyside-main-content{
  padding-bottom: 2rem;
}
.company-editProfile-secondSection{
  height: 40vh;
}
.comapnydash-userprofile-socialHandle-input input{
  height: 80%;
}
.comapnydash-userprofile-socialHandle-input{
  height: 50%;
}

}