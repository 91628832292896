.comapnyLogin-container{
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-login-content{
  width: 40%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.205);
  border-radius: 5px;
  padding-block: 1rem;
}
.company-login-content2{
  width: 40%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.205);
  border-radius: 5px;
  padding-block: 1rem;
}
.company-login-content p{
  font-weight: 600;
  font-size: 1rem;
  width: 70%;
}
.company-login-content h5{
  font-weight: 600;
  font-size: 0.9rem;
  width: 70%;
  text-align: center;
}
.company-login-content span{
  color: #FF3131;
}
.company-login-content button{
  width: 40%;
  height: 10%;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #FF3131;
  cursor: pointer;
}
.login-email-holder{
  width: 70%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.login-email-holder2{
  width: 70%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}
.login-email-holder input{
  width: 100%;
  height: 50%;
  border: solid 1px black;
  border-radius: 10px;
  padding-left: 2rem;
}

.login-email-holder2 input{
  width: 100%;
  height: 50%;
  border: solid 1px black;
  border-radius: 10px;
  padding-left: 2rem;
}
.company-login-content label{
 font-size: 0.8rem;
 font-weight: 500;
}
.company-login-content2 label{
  font-size: 0.8rem;
  font-weight: 500;
 }
.select-number-input{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-number-input select{
  width: 20%;
  height: 100%;
  border-radius: 10px;
  padding-left: 1rem;
}
select:active{
  border: solid 1px black;

}

.select-number-input input{
  width: 75%;
  height: 100%;
}

.terms-and-condtion-holder{
  width: 70%;
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.already-have-account{
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8rem;
}
.already-have-account p{
  font-size: 0.8rem;
}
.password-inputHolder3{
  width: 100%;
  height: 50%;
  border: solid 1px black;
  border-radius: 10px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
}
.password-inputHolder3 input{
  width: 90%;
  border: none;
  outline: none;
  height: 100%;
  padding: 0;
}


@media only screen and (min-width:320px) and (max-width:768px){
  .company-login-content{
    width: 90%;
  }
}