.traveller-profile-mainContainer{
  width: 100%;
  height: max-content;
}
.traveller-profile-main{
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.traveller-profile-itemsContainer{
  width: 30%;
  height: 70%;
  border: solid 1px rgba(0, 0, 0, 0.371);
  border-radius: 5px;
}
.traveller-profile-itemsContainer2{
  width: 50%;
  height: 70%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  gap: 2rem;
  /* justify-content: block-end; */
}
.traveller-profile-itemsContainer2-items{
  width: 90%;
  height: 25%;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.397);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;
}
.traveller-profile-itemsContainer2-items2{
  width: 90%;
  height: 50%;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.356);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.traveller-profile-itemsContainer-top{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
}
.traveller-profile-itemsContainer-top img{
  cursor: pointer;

}
.traveller-profile-itemsContainer-top-sec2{
  width: 100%;
  height: 25%;
  padding-inline: 1rem ;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.traveller-profile-itemsContainer-top-sec2 img{
  height: 90%;
  width: 25%;
  border-radius: 50%;
  border: solid 2px black;
}
.traveller-profile-itemsContainer-top-sec3{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.traveller-profile-itemsContainer-top-sec3 h3{
 width: 70%;
}
.traveller-profile-itemsContainer-top-sec3-item{
  width: 70%;
  height: 20%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.traveller-profile-itemsContainer-top-sec3-item img{
  width: 15px;
  height: 15px;
}
.traveller-profile-itemsContainer2-items2 h3{
  width: 90%;
}
.traveller-profile-itemsContainer2-items2main{
  width: 90%;
  height: 60%;
  /* background-color: chartreuse; */
  display: flex;
  align-items: center;
  gap: 2rem;
  /* justify-content: center; */
}
.traveller-profile-itemsContainer2-items2main-item{
  width: 25%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.traveller-profile-itemsContainer2-items2main-item p{
  font-size: 13px;
  color: rgba(0, 0, 0, 0.511);
}
.traveller-profile-itemsContainer2-items-main1{
  width: 60%;
  height: 100%; 
  display: flex;
  align-items: center;
  padding-left: 2rem;
  gap: 1rem;
}
.traveller-profile-itemsContainer2-items-main1-picsHolder{
  width: 18%;
  height: 70%;
  border-radius: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}





@media only screen and (min-width:320px) and (max-width:576px) {
  .traveller-profile-main{
    flex-direction: column;
    height: max-content;
    margin-block: 2rem;
  }
  .traveller-profile-itemsContainer{
    width: 90%;
    height: 70vh;
    justify-content: space-around;
  }
  .traveller-profile-itemsContainer2{
    width: 90%;
    height: 50vh;
  }
  .traveller-profile-itemsContainer2-items{
    width: 100%;
  }  
  .traveller-profile-itemsContainer2-items2{
    width: 100%;
    
  }
  .traveller-profile-itemsContainer2-items-main1-picsHolder{
    width: 30%;
    height: 60%;
  }
  .traveller-profile-itemsContainer-top-sec2{
    flex-direction: column;
    height: 40%;
    justify-content: center;
    align-items: center;
    gap: 0;
    gap: 1.0rem;
  }
  .traveller-profile-itemsContainer-top-sec2 img{
      height: 44%;
  }
 
  .traveller-profile-itemsContainer-top-sec3{
    padding-top: 0;
    justify-content: flex-start;
  }
}
