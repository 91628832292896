.contact-info-mainContainer{
  width: 100%;
  height: 130vh;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentHeader-container{
  width: 100%;
  height: 6%;
}
.contact-info-content{
  width: 100%;
  height: 92%;
  padding-top: 2rem;
}
.contact-info-item{
  width: 100%;
  height: 27%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}
.contact-info-item-title{
  width: 100%;
  height: 25%;
  background-color: #FFCBCB;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 1rem;
  color: rgba(0, 0, 0, 0.678);
}
.contact-info-inputfill-holder{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-info-item-list{
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.contact-info-item-list2{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.contact-info-item-list input{
  width: 100%;
  height: 35%;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.307);
  padding-left: 1rem;

}
.contact-info-item-list2 input{
  width: 100%;
  height: 35%;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.307);
  padding-left: 1rem;

}
input:focus{
outline: none;
border: solid 1px rgba(0, 0, 0, 0.507);
}
.contact-info-item-list label{
  font-size: 0.9rem;
  font-weight: 500;
}
.contact-info-item-list2 label{
  font-size: 0.9rem;
  font-weight: 500;
}
.contact-info-item3{
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.contact-info-item-title3{
  width: 100%;
  height: 20%;
  background-color: #FFCBCB;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 1rem;
  color: rgba(0, 0, 0, 0.678);
}
.contact-inf0-socialMedia-item{
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.contact-info-socialMedia-input{
  display: flex;
  align-items: center;
  height: 100%;
  height: 40%;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.27) ;
  padding-right: 1rem;

}
.contact-info-socialMedia-item-holder{
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: space-between;
}
.contact-info-socialMedia-input input{
  width: 90%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding-left: 1rem;

}
.contact-info-socialMedia-input label{
  font-size: 0.9rem;
  font-weight: 500;
}
.contact-info-content button{
  width: 20%;
  height: 5%;
  border: none;
  border-radius: 5px;
  background-color: #FF3131;
  color: white;
  cursor: pointer;
}