.company-footer-container{
  width: 100%;
  height: 40vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer-top-section{
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.footer-top-item{
  width: 23%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 0.8rem;
  /* justify-content: center; */
  /* align-items: center; */
}
.footer-top-item1{
  width: 23%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.footer-top-item1 h6{
  width: 90%;
  /* font-size: 0.9rem; */
}
.footer-top-item1 img{
  width: 80%;
  height: 50%;
}
.company-footer-icon-holder{
  width: 80%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company-footer-icon-holder img{
  width: 30px;
  height: 30px;
}
.footer-top-item h4{
  width: 90%;
}
.footer-top-item p{
  width: 50%;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.789);
}
.footer-bottom-section{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 0.5rem;
  border-top: solid 1px rgba(255, 255, 255, 0.176);
}

.footer-bottom-section img{
  width: 20px;
  height: 20px;
}

.footer-bottom-section p{
  font-size: 0.8rem;
}


@media only screen and (min-width:320px) and (max-width:576px){
  .company-footer-container{
    height: 100vh;
  }
  
  .footer-top-section{
    flex-direction: column;
  }
  .footer-top-item1{
    width: 90%;
  }
  .company-footer-icon-holder{
    width: 50%;
  }
  .footer-top-item{
    width: 90%;
  }
}
