.FAquestions-container{
  height: 120vh;
  width: 100%;
}
.FAQuestion-redbackground{
width: 100%;
height: 20%;
background-image: url('../../images/redRectangle.svg');
display: flex;
align-items: center;
justify-content: center;
color: white;
font-weight: 600;
font-size: 30px;
}
.FAQues-mainContainer{
  width: 100%;
  height: 78%;
  gap: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FAQues-leftSection{
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}
.FAQues-rightSection{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: end;
  padding-bottom: 1%;
}
.FAQues-rightSection img{
  height: 90%;
  width: 70%;
}
.FAQues-lefSec-topSec{
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.FAQues-lefSec-topSec h5{
  color: red;
}
.FAQues-lefSec-bottomSec{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 01rem;
}
.FAQues-items{
  width: 100%;
  max-height: 30%;
}
.FAQues-holder{
  height: 7vh;
  width: 70%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border: solid 1px black;
  border-radius: 5px;
  justify-content: space-between;
}
.FAQues-answer{
  height: 18vh;
  width: 70%;
  display: flex;
  align-items: center;
  padding: 1rem;
  /* border: solid 1px black; */
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #D9D9D9;
}