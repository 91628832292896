.login-mainContainer{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.login-Header{
  width: 70%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-header-logo-holder{
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
}

.login-btn-holder{
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
}
.login-btn-holder button{
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.login-btn-login{
  background-color: #000000;
}
.login-btn-signup{
  background-color: #FF3131;
}
.login-header-search{
  width: 5%;
  height: 40%;
}





.login-container{
  width: 45%;
  height: 60%;
  display: flex;
  align-items: center;justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
   border-radius: 20px;
}
.login-container-leftSection{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FF3131;
}
.login-container-rightSection{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  gap: 1rem;
  justify-content: center;
}
.login-container-input{
  width: 90%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.login-container-input input{
  width: 90%;
  height: 60%;
  border: solid 1px rgba(0, 0, 0, 0.350);
  border-radius: 4px;
  padding-left: 0.3rem;
}
.login-container-input label{
  font-size: 0.7rem;
  font-weight: bolder;
}
.login-container-checkbox{
  width: 90%;
  height: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.7rem;
  
  
}
.login-container-rightSection button{
  width: 40%;
  height: 10%;
  color: white;
  background-color: #FF3131;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.login-footer{
  width: 90%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.7rem;

}
.login-container-createaccount{
  font-size: 0.7rem;
}
.login-container-createaccount span{
  cursor: pointer;
  color: #FF3131;
}
input:focus{
  outline: none;
  /* border: transparent; */

}
.login-footer img{
  width: 20px;
  height: 20px;
}
