.payment-main-container{
  width: 100%;
  height: 150vh;
  padding-top: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.paymentHeader-container{
  width: 100%;
  height: 6%;
}
.payment-container{
  width: 100%;
  height: 92%;
  display: flex;
  flex-direction: column;
}
.payment-Header{
  width: 100%;
  height: 5%;
}
.payments-history{
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  border:none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}
.payment-history-searchbar{
  width: 100%;
  height: 6%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-history-searchbar input{
  width: 90%;
  height: 80%;
  border: none;
  padding-left: 2rem;
}
.payment-history-searchbar img{
  height: 15px;
  height: 15px;
  padding-right: 1rem;
  cursor: pointer;
}
.filterContainer{
  width: 10%;
  height: 95%;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}
.filterContainer img{
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.payment-history-labels{
  width: 100%;
  height: 4%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}
.payment-history-labels p{
  width: 20%;
  padding-left: 2rem;
}
.payment-list-holder{
  width: 100%;
  height: 75%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.payment-list-item{
  width: 100%;
  height: 10%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.209);
  display: flex;
  align-items: center;
}
.payment-list-item p{
  width: 20%;
  padding-left: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
}
.payment-list-item img{
  width: 25px;
  padding-right: 1.5rem;
}
.payment-history-navigation{
  display: flex;
  width: 100%;
  height: 15%;
  align-items: center;
  padding-left: 4rem;
}
.payment-history-item{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
}
.payment-history-item2{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
}
.payment-history-item2-active{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
  background-color: #FFCBCB;
}
.payment-history-item3{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
}