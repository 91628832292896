.TraveEditProfile-main{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.editProfile-container{
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.editProfile-top{
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.editProfile-btm{
    height: 90vh;
    width: 100%;
    display: flex;
}
.editProfile-btm-left{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}
.editProfile-btm-right{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}
.editProfile-btm-left img{
    width: 40%;
    height: 15%;
    position: relative;
    border-radius: 50%;
}
.changeProPics{
    width: 8%;
    height: 15%;
    position: absolute;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    justify-content: center;  
    cursor: pointer;

}
.changeProPics p{
    font-size: 10px;
    font-weight: bolder;
}
.editProfile-btm-right-sec1{
    height: 15%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.editProfile-btm-right-sec12{
    height: 15%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.editProfile-btm-right-sec1a{
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}
.editProfile-btm-right-sec1a input{
    padding-left: 0.5rem;
    width: 100%;
    height: 40%;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.253);
}
.editProfile-btm-right-sec12a{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
}
.gender-option{
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.gender-option-a{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.editProfile-btm-right-sec12b{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
}
.editProfile-btm-right-sec12b select{
    padding-left: 0.5rem;
    width: 100%;
    height: 40%;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.253);
}
.editProfile-btm-right-sec12c{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
}
.editProfile-btm-right-sec12c input{
    padding-left: 0.5rem;
    width: 100%;
    height: 40%;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.253);
}
.editProfile-btm-right-sec13a{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
}
.editProfile-btm-right-sec13a input{
    padding-left: 0.5rem;
    width: 100%;
    height: 40%;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.253);
}
.editProfile-btm-right-sec13b{
    width: 35%;
    height: 100%;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: center;
}
.editProfile-btm-right-sec13b select{
    padding-left: 0.5rem;
    width: 100%;
    height: 40%;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.253);
}
.editProfile-btm-right-sec1 button{
    width: 18%;
    height: 45%;
    border-radius: 5px;
    background-color: red;
    border: none;
    color :white;
    cursor: pointer;
}
.fileChange-holder{
    width: 50%;
    opacity: 10%;
    border: none;
    background: transparent;
}


@media only  screen and (min-width:320px) and (max-width:768px) {
  .editProfile-btm{
    display: flex;
    flex-direction: column;
    height: max-content;
  }
  
  .editProfile-btm-left{
    height: 20vh;
    width: 100%;
  }
  .editProfile-btm-left img{
    height: 90%;
    width: 30%;
  }
  .editProfile-btm-right{
    height: 150vh;
    width: 100%;
  }
  .editProfile-btm-right-sec1{
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.editProfile-btm-right-sec12{
    height: 25%;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;   
    justify-content: center;
}
  .editProfile-btm-right-sec1a{
    width: 95%;
  }
  .editProfile-btm-right-sec12a{
    width: 95%
  }
  .editProfile-btm-right-sec12b{
    width: 95%
  }
  .editProfile-btm-right-sec12c{
    width: 95%
  }
  .editProfile-btm-right-sec13a{
    width: 95%;
  }
  .editProfile-btm-right-sec13b{
    width: 95%;
  }
  .editProfile-btm-right-sec1 button{
    width: 40%;
    height: 30%;
  }
}