.company-payment-container{
  width: 68%;
  height: max-content;
  min-height: 90vh;
}
.status-button{
  /* background-color: aqua; */
  height: 60%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 15px;
  border: solid 1px green;
  color: green;
}
.payment-listHolder{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: green;
}
.accountDashboard{
  width:95%;
  height: 30vh;
  background-color: red;
  border: none;
  border-radius: 10px;
  margin-top: 10vh;
  display: flex;
}
.accountDashboard-left{
  width: 50%;
  height: 100%;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  gap: 1rem;
}
.accountDashboard-right{
  width: 50%;
  height: 100%;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(255, 244, 244);
  gap: 1rem;
}
.accountDashboard-right button{
  width: 60%;
  height: 20%;
  background-color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.accountDashboard-right input{
  width: 60%;
  height: 20%;
  background-color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: black;
  padding-left: 1rem;
}
.accountDashboard-right select{
  width: 60%;
  height: 20%;
  background-color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: black;
  padding-left: 1rem;
}
.balHolder{
  width: 90%;
  height: 30%;
  background-color: white;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: black;
  border: none;
  border-radius: 10px;
  font-size: 30px;
  font-weight: bolder;
  padding-left: 1rem;
}
.payment-holder-items{
  display: none;
}

@media only screen and (min-width:320px) and (max-width:768px) {
  .company-payment-container{
    width: 90%;
    min-height: 50vh;
    gap: 1rem;
  }
  .accountDashboard{
    width: 100%;
    height: 50vh;
    flex-direction: column;
  }
  .accountDashboard-left{
    height: 50%;
    width: 95%;
    padding-left: 1rem;
  }
  .accountDashboard-right{
    height: 50%;
    width: 95%;
    padding-left: 1rem;
  }
  .balHolder{
    width: 100%;
    height: 50%;
  }
  .clientManagement-history-labels{
    display: none;
  }

  .clientManagement-list-holder{
    display: none;
  }
  .payment-holder-items{
    width: 100%;
    height: 40vh;
    border: none;
    border: solid 1px rgba(0, 0, 0, 0.416);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }
  .payment-holder-items1{
    width: 100%;
    height: 20%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.414);
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    justify-content: center;

  }
  .payment-holder-items2{
    width: 100%;
    height: 15%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.414);
    display: flex;
    padding-inline: 1rem;
    justify-content: space-between;
    align-items: center;
  }
  .payment-holder-items3{
    width: 100%;
    height: 20%;
    display: flex;
    padding-inline: 1rem;
    justify-content: flex-end;
    align-items: center;
  }
  .payment-holder-items2a{
    display: flex;
    gap: 0.5rem;
    height: 100%;
    align-items: center;
  }
  .company-tickets-maincontainer{
    width: 100%;
    height: max-content;
    padding-block: 1rem;
  }
  .clientManagement-history{
    height: max-content;
    min-height: 50vh;
    flex-wrap: wrap;
    width: 100%;
    padding-inline: 5%;
    gap: 1rem;
  }
  .clientManagement-history-searchbar{
    height: 7vh;
  }
}