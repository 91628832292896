.company-notification-container{
    width: 100%;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .comNot-main{
    width: 90%;
    height: 95%;
  }
  .comNot-headline-conatiner{
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  .comNot-headline-conatiner img{
    width: 25px;
    height: 25px;
  }
  .comNot-filter-conatiner{
    width: 100%;
    height: 8%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
  .comNot-subheadline-conatiner{
    width: 100%;
    height: 5%;
  }
  .comNot-item-conatiner{
    width: 90%;
    height: 78%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 1rem;
    align-items: center;
    padding-top: 1rem;
  }
  select{
    padding-inline: 10px;
    /* padding-block: 15px; */
    width: 12%;
    height: 70%;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.212);
  }
  
  .comNot-item{
    width: 98%;
    height: 15%;
    border-radius: 5px;
    box-shadow: 0 0  5px rgba(0, 0, 0, 0.207);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 1rem;
  }
  .comNot-item img{
    width: 40px;
    height: 40px
  }