.clientManagement-main-container{
  width: 100%;
  height: 150vh;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.clientManagementHeader-container{
  width: 100%;
  height: 5%;
}
.clientManagement-Header-holder{
  width: 100%;
  height: 10%;
  /* background-color: aqua; */
  display: flex;
  gap: 3rem;
  /* align-items: center; */
}
.clientManagement-container{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
}
.clientManagement-container2{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
}
.clientManagement-Header{
  width: 100%;
  height: 5%;
}
.clientmanager-btn{
  width: 25%;
  height: 60%;
  border: none;
  border-radius: 20px;
  color: #FF3131;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: transparent;


}
.clientmanager-btn-active{
  width: 25%;
  height: 60%;
  border: none;
  border-radius: 20px;
  background-color: #FF3131;
  cursor: pointer;
  color: white;

}
.clientManagement-history{
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  border:none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}
.clientManagement-history-searchbar{
  width: 100%;
  height: 6%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clientManagement-history-searchbar input{
  width: 90%;
  height: 80%;
  border: none;
  padding-left: 2rem;
}
.clientManagement-history-searchbar-img{
  height: 15px;
  height: 15px;
  padding-right: 1rem;
  cursor: pointer;
}
.filterContainer{
  width: 10%;
  height: 95%;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}
.filterContainer-img{
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.clientManagement-history-labels{
  width: 100%;
  height: 6%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 2rem;
}
.clientManagement-history-labels2{
  width: 100%;
  height: 6%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}
.company-name-holder p{
  width: 25%;
}
.company-name-holder{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

}
.company-name-holder2{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
}
.xChnage{
  width: 25%;
}
.company-name2{
  width: 75%;
}
.company-name-holder2 img{
  width: 35px;
  height: 25px;
}
.company-name{
  width: 100%;
  padding-left: 2rem;
}
.company-name{
  width: 70%;
  padding-left: 2rem;
}
.clientManagement-history-labels p{
  width: 30%;
  padding-left: 2rem;
}
.Company-date{
  width: 20%;
  padding-left: 2rem;
}
.company-status{
  width: 25%;
  padding-left: 2rem;
}
.clientManagement-history-labels2az{
  width: 100%;
  height: 6%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 2rem;
}
.clientManagement-history-labels2az p{
  width: 20%;
  padding-left: 2rem;
}
.clientManagement-list-holder{
  width: 100%;
  height: 75%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.clientManagement-list-holderza{
  width: 100%;
  height: 75%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.clientManagement-list-holderza p{
  width: 20%;
}
.clientManagement-list-item{
  width: 100%;
  height: 10%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.209);
  display: flex;
  align-items: center;
  gap: 2rem;
}
.ticketID-company-holder{
  width: 30%;
}
.clientManagement-list-item p{
  width: 30%;
}
.clientManagement-list-itemaz p{
  width: 30%;
  padding-left: 2rem;
}
.clientManagement-list-item2{
  width: 100%;
  height: 10%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.209);
  display: flex;
  align-items: center;
}
.clientManagement-list-item2 p{
  width: 20%;
  padding-left: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #FF3131;
  
}
.clientManagement-list-item-btn-holder{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 2rem;

}
.clientManagement-list-item-btn-holder button{
  width: 60%;
  height: 60%;
  border: solid 1.5px #1FF234;
  color: #1FF234;
  background-color: transparent;
  border-radius: 15px;
}
.clientManagement-trips{
  width: 18%;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.clientManagement-trips p{
  width: 30%;
  padding: 0;
}
/* .tickets-trips img{
  width: 25px;
  height: 25px;
  padding-right: 0;
} */
.trips-icon{
  width: 15px;
  height: 15px;
}
.clientManagement-list-item-img-container{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.2rem;
}
.clientManagement-list-item-img{
  width: 15px;
  height: 15px;
}
.clientManagement-list-item-img2-container{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.clientManagement-list-item-img2{
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.clientManagement-history-navigation{
  display: flex;
  width: 100%;
  height: 15%;
  align-items: center;
  padding-left: 4rem;
}
.clientManagement-history-item{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
}
.clientManagement-history-item2{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
}
.clientManagement-history-item2-active{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
  background-color: #FFCBCB;
}
.clientManagement-history-item3{
  width: 5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border: solid 1px rgba(0, 0, 0, 0.205);
  cursor: pointer;
}
.clientManagement-footer{
  width: 100%;
  height: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.abount-company-image-holder{
  width: 100%;
  height:20%;
  display: flex;
  padding-left: 0;
  margin: 0;
  align-items: center;
  
}
.abount-company-image-holder img{
  width: 20%;
  height: 70%;
}
.comany-name-input{
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.comany-name-input input{
  width: 40%;
  height: 60%;
 border: solid 1px rgba(0, 0, 0, 0.205);
 border-radius: 5px;
 padding-left: 1rem;
}
.comany-about-input{
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.comany-about-input input{
  width: 100%;
  height: 80%;
 border: solid 1px rgba(0, 0, 0, 0.205);
 border-radius: 5px;
 padding-left: 1rem;
}
.company-image-holders{
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company-image-holders img{
width: 25%;
height: 80%;
}
.socialMedia-Handler-holder{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.socialMedia-handlers-item{
width: 32%;
height: 90%;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.socialMedia-handlers{
  width: 100%;
  height: 70%;
  border: solid 1px rgba(0, 0, 0, 0.202);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.socialMedia-handlers input{
  width: 90%;
  height: 100%;
  border:none;
  background-color: transparent;
  padding-left: 1rem;
}
.socialMedia-handlers img{
  width: 20px;
  height: 20px;
  
}
.approval-btn{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.approval-btn button{
  width: 20%;
  height: 50%;
  border-radius: 10px;
  cursor: pointer;
}
.approve-btn{
  background-color: #158602;
  color: white;
  border: none;

}
.Decline-btn{
  background-color: red;
  color: white;
  border: none;

}

.Suspend-btn{
  border: solid 1px #FF3131;
  color: black;
  background-color: transparent;
}

.delete-btn{
  border: solid 1px #FF3131;
  color: #FF3131;
  background-color: transparent;
}