.driverSignup-holder{
    width: 100%;
    min-height: 50vh;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 2rem;
    flex-direction: column;
}
.driverSignup-mainContainer{
    width: 40%;
    height: max-content;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.255);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    flex-direction: column;
    gap: 1rem;
}
.driverSignup-mainContainer2{
    width: 40%;
    min-height: 50vh;
    height: max-content;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.255);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    flex-direction: column;
    gap: 1rem;
}
.driverSignup-mainContainer label{
    font-size: 13px;
    font-weight: 500;
}
.driverSignup-mainContainer2 label{
    font-size: 13px;
    font-weight: 500;
}
.driverSignup-mainContainer-item1{
    /* width: 90%; */
    height: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.driverSignup-mainContainer-item1a{
    /* width: 90%; */
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.driverSignup-mainContainer-item2{
    height: 12vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.driverSignup-mainContainer-item2 input{
    width: 100%;
    height: 65%;
    border-radius: 7px;
    border: solid 1px rgba(0, 0, 0, 0.298);
    padding-left: 1rem;
}
.driverSignup-mainContainer-item2 select{
    width: 100%;
    height: 65%;
    border-radius: 7px;
    border: solid 1px rgba(0, 0, 0, 0.298);
    padding-right: 1rem;
}
.driverSignup-mainContainer button{
    width: 20%;
    height: 6vh;
    background-color: red;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.driverSignup-mainContainer-item3{
    width: 80%;
    height: 5vh;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.driverSignup-mainContainer span{
    color: red;
}
.driverSignup-mainContainer2 h5{
    width: 70%;
    text-align: center;
    font-size: 12px;
}
.driverSignup-mainContainer2 button{
    /* width: 25%;
    height: 6vh; */
    background-color: red;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    padding-block: 0.8rem;
    padding-inline: 1.2rem;
}
.driverSignup-mainContainer-item6{
    width: 80%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.driverSignup-mainContainer-item6a{
    width: 50%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.driverSignup-mainContainer-item6 p{
    font-size: 12px;
    font-weight: 500;
}


@media only screen and (min-width:320px) and (max-width:768px) {
    .driverSignup-mainContainer{
        width: 90%;
        padding: 1rem;
        box-shadow: none;
    }
    .driverSignup-mainContainer2{
        width: 90%;
        padding: 1rem;
        box-shadow: none;
    }
    .driverSignup-mainContainer-item1{
        height: 15vh;
        justify-content: space-around;
    }
    .driverSignup-mainContainer-item1 h2{
        text-align: center;
    }
    .driverSignup-mainContainer-item2{
        width: 95%;
    }
    .driverSignup-mainContainer-item3{
        width: 95%;
    }
    .driverSignup-mainContainer button{
        width: 30%;
    }
    .driverSignup-mainContainer button{
        width: 30%;
    }
}