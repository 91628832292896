.travellerFAQs-main{
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.traveller-ContactUs-container{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.travellerFAQs-main-container{
  width: 100%;
  height: 100vh;
  display: flex;align-items: center;
  justify-content: center;
}
.FAquestions-container{
  height: 120vh;
  width: 100%;
}

.FAQues-mainContainer2{
  width: 100%;
  height: 78%;
  gap: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FAQues-leftSection{
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}
.FAQues-rightSection2{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: end;
  padding-bottom: 1%;
}
.FAQues-rightSection2 img{
  height: 90%;
  width: 100%;
}
.FAQues-lefSec-topSec{
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.FAQues-lefSec-topSec h5{
  color: red;
}
.FAQues-lefSec-bottomSec{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 1rem;
}
.FAQues-items{
  width: 100%;
  min-height: 30%;
  height: max-content;
}
.FAQues-holder{
  height: max-content;
  min-height: 7vh;
  width: 70%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border: solid 1px black;
  border-radius: 5px;
  justify-content: space-between;
}
.FAQues-answer{
  min-height: 7vh;
  height: max-content;
  width: 70%;
  display: flex;
  align-items: center;
  padding: 1rem;
  /* border: solid 1px black; */
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #D9D9D9;
}
@media only screen and (min-width:320px) and (max-width:576px) {
  .travellerFAQs-main-container{
    height: max-content;
  }
  .FAQues-mainContainer2{
    flex-direction: column-reverse;
    height: 150vh;
  }
  .FAQues-leftSection{
    height: 100vh;
    width: 90%;
  }
  .FAQues-rightSection2{
    height: 50vh;
    width: 90%;
  }
  .FAQues-lefSec-topSec{
    width: 100%;
  }
  .FAQues-lefSec-bottomSec{
    width: 100%;
  }
  .FAQues-items{
    width: 100%;
  }
  .FAQues-holder{
    width: 100%;
  }
  .FAQues-answer{
    width: 100%;
  }
}