.forgotPassWord-container{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgotPassWord-container-left{
    width: 25%;
    height: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.forgotPassWord-container-left2{
    width: 35%;
    height: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.forgotPassWord-container-right{
    width: 45%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgotPassWord-container-right img{
    width: 80%;
    height: 100%;
}
.forgotPassWord-container-right2{
    width: 45%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgotPassWord-container-right2 img{
    width: 80%;
    height: 100%;
}
.forgotPassWord-container-right-item1{
    width: 80%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}
.forgotPassWord-container-right-item1 input{
    width: 70%;
    height: 55%;
    padding-left: 1rem;
    background-color: none;
    border: solid 1px rgba(0, 0, 0, 0.244);
    border-radius: 5px;
}
.forgotPassWord-container-left button{
    width: 25%;
    height: 8%;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    background-color: red;
}
.forgotPassWord-container-left2 button{
    width: 35%;
    height: 8%;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    background-color: red;
}
@media only screen and (min-width: 320px) and (max-width:768px) {
    .forgotPassWord-container{
        flex-direction: column-reverse;
        height: 100vh;
        padding-top: 1rem;
    }
    .forgotPassWord-container-right{
        width: 90%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .forgotPassWord-container-right2{
        width: 90%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .forgotPassWord-container-left{
        width: 80%;
        height: 50%;
        justify-content: center;
        align-items: center;
    }
    .forgotPassWord-container-left2{
        width: 80%;
        height: 50%;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }
    .forgotPassWord-container-right-item1 input{
        width: 100%;
    }
    .forgotPassWord-container-right-item1{
        width: 100%;
        height: 20%;
    }
    .forgotPassWord-container-left2 h1{
        font-size: 30px;
    }
    .forgotPassWord-container-left2 p{
        text-align: justify;
    }
    .forgotPassWord-container-left2 button{
        width: 50%;
        height: 10%;
        border-radius: 25px;
    }
    
}