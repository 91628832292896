.TransportDetails-mainContainer {
    width: 100%;
    height: max-content;
}

.TransportDetails-main {
    width: 100%;
    height: max-content;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 2rem;
}

.transportDetails-topsec {
    width: 90%;
    height: 10vh;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.traveleerSide-ttransporcompanies-topsec123 {
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../images/backgrndImg1.png") no-repeat center center;
    background-size: cover;
    color: white;
}

.transportDetails-sec2 {
    width: 90%;
    height: 30vh;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.transportDetails-sec2 img {
    width: 10%;
    height: 80%;
    border: none;
    border-radius: 10px;

}

.transportDetails-sec2a {
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    gap: 0.5rem;

}

.transportDetails-Rating {
    display: flex;
    font-size: 12px;
    width: 100%;
    height: 5vh;
    align-items: center;
    gap: 0.3rem;
}

.transportDetails-Rating img {
    width: 10px;
    height: 10px;
}

.transportDetails-sec3 {
    width: 90%;
    height: max-content;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.transportDetails-sec3-top {
    width: 100%;
    height: 10vh;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.272);
    display: flex;
    align-items: center;
    gap: 15rem;
    padding-inline: 4rem;
    font-size: 14px;
    font-weight: 600;
}

.transportDetails-sec3-top p {
    cursor: pointer;
    padding-bottom: 0.7rem;

}

.transportDetails-sec3-top-active {
    border-bottom: solid 2px red;
}

.transportDetails-sec4 {
    width: 100%;
    height: max-content;
    min-height: 50vh;
    flex-direction: column;
    justify-content: center;
}

.transportDetails-sec4 p {
    width: 100%;
    min-height: 30vh;
    height: max-content;
    font-size: 14px;
}

.tranportDetails-imgHolder {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tranportDetails-imgHolder img {
    width: 32%;
    height: 90%;
    border: none;
    border-radius: 15px;
    background-color: antiquewhite;

}

.transportDetails-Socials {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    gap: 4rem;
}

.transportDetails-Socials-item1 {
    width: 20%;
    height: 70%;
    border: solid 1px rgba(0, 0, 0, 0.235);
    display: flex;
    border-radius: 10px;
    align-items: center;
    padding-left: 2rem;
    gap: 3rem;
    cursor: pointer;
}

.transportDetails-Socials-item2 {
    width: 20%;
    height: 70%;
    border: none;
    display: flex;
    border-radius: 10px;
    align-items: center;
    padding-left: 2rem;
    gap: 3rem;
    cursor: pointer;
    background-color: #2033DC;
    color: white;
}

.transportDetails-Socials-item3 {
    width: 20%;
    height: 70%;
    border: none;
    display: flex;
    border-radius: 10px;
    align-items: center;
    padding-left: 2rem;
    gap: 3rem;
    cursor: pointer;
    background-color: #05C3FF;
    color: white;
}

.transportDetails-priceTag {
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 14px;
}

.transportDetails-sec2a h6 {
    color: red;
}

.transportDetails-trips {
    width: 100%;
    /* height: max-content; */
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.transportDetails-trips-item1 {
    width: 100%;
    height: 18vh;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.222);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
}

.transportDetails-trips-item1-top {
    width: 100%;
    height: 28%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.257);
    padding-inline: 2rem;
    display: flex;
    align-items: center;
}

.transportDetails-trips-item1-btm {
    width: 100%;
    height: 28%;
    border-top: solid 1px rgba(0, 0, 0, 0.257);
    display: flex;
    padding-inline: 2rem;
    align-items: center;
    justify-content: space-between;
}

.transportDetails-trips-item1-mid {
    width: 100%;
    height: 44%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 2rem;
}

.transportDetails-trips-item1-mida {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 12px;
}

.transportDetails-trips-item1-midb {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.transportDetails-trips-item1-midc {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-end;
    font-size: 12px;
}

.transportDetails-trips-item1-mid p {
    font-size: 12px;
}

.transportDetails-trips-item1-top p {
    font-size: 12px;
}

.transportDetails-trips-item1-btm p {
    font-size: 12px;
}

.transportDetails-trips-item1-btmA {
    display: flex;
    align-items: center;
    font-size: 12px;
}
.transportDetails-Terminals{
     width:100%;
     height: max-content;
    }
.transportDetails-Terminals-item1{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
}
.transportDetails-Terminals-item1a{
    width: 100%;
    height: 6vh;
    display: flex;
    align-items: center;
}
.transportDetails-Terminals-item1b{
    width: 100%;
    height: max-content;
    min-height: 20vh;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.transportDetails-Terminals-item1b button{
    width: 16%;
    height: 40px;
    border: solid 1px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
}





@media only screen and (min-width:320px) and (max-width:768px) {
    .transportDetails-sec2 {
        gap: 0.5rem;
    }

    .transportDetails-sec2 img {
        width: 30%;
        height: 50%;
    }

    .transportDetails-sec2a {
        gap: 0.1rem;
        width: 68%;
    }

    .transportDetails-sec2a h3 {
        font-size: 14px;
    }

    .transportDetails-sec3-top {
        padding-inline: 2rem;
        gap: 0;
        justify-content: space-between;
    }

    .transportDetails-sec4 {
        gap: 1rem;
        display: flex;
    }

    .transportDetails-sec4 p {
        width: 100%;
        text-justify: distribute;
    }

    .tranportDetails-imgHolder {
        height: 70vh;
        flex-direction: column;
    }

    .tranportDetails-imgHolder img {
        width: 100%;
        height: 30%;
    }

    .transportDetails-Socials {
        gap: 1rem;
    }

    .transportDetails-Socials-item1 {
        width: 32%;
        height: 55%;
        gap: 0.5rem;
        padding-left: 0.5rem;
    }

    .transportDetails-Socials-item2 {
        width: 32%;
        height: 55%;
        gap: 0.5rem;
        padding-left: 0.5rem;
        font-weight: 400;
    }

    .transportDetails-Socials-item3 {
        width: 32%;
        height: 55%;
        gap: 0.5rem;
        padding-left: 0.5rem;
        font-weight: 400;
    }

    .transportDetails-Socials-item1 img {
        width: 15px;
        height: 15px;
    }

    .transportDetails-Socials-item2 img {
        width: 15px;
        height: 15px;
    }

    .transportDetails-Socials-item3 img {
        width: 15px;
        height: 15px;
    }
    .transportDetails-Terminals-item1b button{
        width: 30%;
    }
}