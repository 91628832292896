.trips-topSection{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trips-topSection button{
    padding-inline: 2rem;
    padding-block: 0.8rem;
    background-color: red;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.trips-filter-holder{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
}
.icon12{
    border-right: solid 1px rgba(0, 0, 0, 0.268);
    padding-right: 2rem;
}
.icon13{
    cursor: pointer;
}
.trips-labelHolder{
    width: 100%;
    height: 10vh;
    border-bottom: solid 1.5px rgba(0, 0, 0, 0.268);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trips-labelHolder-item1{
    width: 20%;
}
.trips-labelHolder-item2{
    width: 10%;
}
.trips-labelHolder-item3{
    width: 15%;
}
.trip-titleHolder{
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5px;
}
.trip-titleHolder-a{
    display: flex;
    align-items: center;
    width: 10%;
    align-items: center;
    gap: 0.5rem;
}
.trip-titleHolder-b{
    display: flex;
    align-items: center;
}
.trip-titleHolder-a2{
    display: flex;
    align-items: center;
    width: 100%;
    align-items: center;
    gap: 0.5rem;
}
.addNewTrip-mainContainer{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.addTrips-section1{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    gap: 2rem;
}
.addTrips-section1a{
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.addTrips-section1-itemA{
    width: 100%;
    height: 60%;
    border: solid 1px rgba(0, 0, 0, 0.341);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 0.5rem;
}
.addTrips-section1-itemA input{
    background-color: transparent;
    border: none;
    width: 80%;
}
.arrowHolder{
    padding-top: 2rem;
}
.addTrips-section2{
    width: 45%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.addTrips-section3{
    width: 60%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.addTrips-select{
    background-color: transparent;
    border: none;
    width: 95%;
    height: 100%;
    box-shadow: none;
}
.AddTripBtn{
    border: none;
    background-color: red;
    color: white;
    border-radius: 10px;
    padding-inline: 2.5rem;
    padding-block: 0.5rem;
    cursor: pointer;
}
.viewTrips-mainContainer{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
}
.viewTrips-section1{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    gap: 2rem;
}
.viewTrips-section1a{
    width: 12%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.viewTrips-section1a1{
    width: 100%;
    display: flex;
    height: 60%;
    align-items: center;
    justify-content: space-between;
}
.viewTrips-section2{
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.viewTrips-priceHolder{
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
}
.viewTrips-butttonHolder{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
}
.viewTrips-butttonHolder2{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
}
.viewTrips-butttonHolder button{
    padding-inline: 2.5rem;
    padding-block: 0.8rem;
    border-radius: 20px;
    background-color: black;
    color: white;
    cursor: pointer;
    border: none;
}
.viewTrips-btn1{
    padding-inline: 2rem;
    padding-block: 0.6rem;
    border-radius: 20px;
    background-color: blue;
    color: white;
    cursor: pointer;
    border: none;
}
.viewTrips-btn2{
    padding-inline: 2rem;
    padding-block: 0.6rem;
    border-radius: 20px;
    background-color: red;
    color: white;
    cursor: pointer;
    border: none;
}