.company-tracking-container{
  width: 68%;
  height: 150vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.company-dash-toplayer{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.company-tracking-searchHolder{
  width: 98%;
  height: 40vh;
  background-color: black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  gap: 1rem;
}
.company-tracking-searchHolder label{
  font-size: 20px;
}
.company-tracking-searchHolder input{
  width: 50%;
  height: 20%;
  padding-left: 1rem;
  border-radius: 5px;
}
.company-tracking-tripDetails{
  width: 98%;
  height: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}


@media only screen and (min-width:320px) and (max-width:768px) {
  .company-tracking-container{
    width: 90%;
    height: max-content;
    min-height: 90vh;
  }
  .company-dash-toplayer{
    display: none;
  }
  .company-tracking-searchHolder{
    height: 20vh;
  }
}