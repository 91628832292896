.travellerTickets-main{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;
    flex-direction: column;

}
.travellerTicketsDetails-main{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
}
.travellerTicketDetails-container{
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.travellerTicket-details-item1{
    width: 90%;
    height: 15vh;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.travellerTicket-details-item1a{
    width: 40%;
    height: inherit;
    display: flex;
    align-items: center;
}
.travellerTicket-details-item1a img{
    width: 30%;
}
.travellerTicket-details-item1a1{
    width: 60%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}
.travellerTicket-details-item1b{
    width: 60%;
    height: inherit;
    display: flex;
    align-items: center;
}
.travellerTicketDetails-item1b1{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
}
.travellerTicketDetails-item1b1a{
    width: 30%;
    height: inherit;
    display: flex;
    align-items: center;
    gap: 15px;
}
.travellerTicketDetails-item1b1c{
    width: 40%;
    height: inherit;
    display: flex;
    align-items: center;
    gap: 15px;
}
.travellerTicket-details-item1-details{
    width: 90%;
    height: 50vh;
    display: flex;
    justify-content: space-between;
}
.travellerTicket-details-item1-details-item1{
    width: 65%;
    height: 100%;
    background-color: #F8F8F8;
}
.travellerTicket-details-item1-details-item2{
    width: 33%;
    height: 85%;
    background-color: #F8F8F8;
    /* border: solid 1px black; */
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.travellerTicket-details-item1-details-item1-top{
    width: 100%;
    height: 20%;
    border: solid 1px rgba(0, 0, 0, 0.26);
    border-radius: 10px 10px 0 0 ; display: flex;
}
.travellerTicket-details-item1-details-item1-btm{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.travellerTicket-details-item1-details-item1-btm-item1{
    width: 90%;
    height: 20%;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.travellerTicket-details-item1-details-item1-btm-item2{
    width: 90%;
    min-height: 60%;
    color: red;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.travellerTicket-details-item1-details-item1-btm-item1a{
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.travellerTicket-details-item1-details-item1-btm-item1a img{
    width: 25px;
    height: 20px;
}
.locationDot{
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
}
.locationDot2{
    width: 7px;
    height: 7px;
    background-color: red;
    border-radius: 50%;
}





.travellerTicket-details-item1-details-item1-top2{
    width: 100%;
    height: 23.5%;
    border: solid 1px rgba(0, 0, 0, 0.26);
    border-radius: 10px 10px 0 0 ;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ravellerTicket-details-item1-details-item1-btm2{
    width:90%;
    height: 70.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.travellerTicket-details-item1-details-item1-topa{
    width: 50%;
    height: 100%;
    border-right: solid 1px rgba(0, 0, 0, 0.253);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.travellerTicket-details-item1-details-item1-topb{
    width: 50%;
    height: 100%;
     display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.travellerTicket-details-item1-details-item1-topa1{
    width: 90%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}
.travellerTicket-details-item1-details-item1-topa2{
    width: 90%;
    height: inherit;
    display: flex;
    align-items: center;
    font-weight:600; 
    gap: 1rem;
}
.travellerTicket-details-item1-details-item1-topa2 img{
    width: 20px;
    height: 20px;
}
.ravellerTicket-details-item1-details-item1-btm2-item{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.ravellerTicket-details-item1-details-item1-btm2-itema{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ravellerTicket-details-item1-details-item1-btm2-item label{
    font-size: 15px;
    color: rgba(0, 0, 0, 0.35);
}
.ravellerTicket-details-item1-details-item1-btm2-itema p{
    font-size: 10px;
}
.ravellerTicket-details-item1-details-item1-btm2-itema-h4{
    color: red;
}





@media only screen and (min-width : 320px) and (max-width : 768px) {
    .travellerTickets-main{
        padding: 0;
        padding-block: 1rem;
    }
    .travellerTicketsDetails-main{
        border: solid 1px rgba(0, 0, 0, 0.216); 
        padding: 0;
        padding-block: 1rem;
        width: 90%;
        border-radius: 10px;
    }
    
    .travellerTicketDetails-container{
        height: 20vh;
        width: 100%;
        border: none;
    }
    .travellerTicket-details-item1{
        flex-direction: column;
    }
    .travellerTicket-details-item1a{
        width: 90%;
        height: 50%;
    }
    .travellerTicket-details-item1a img{
        width: 15%;
    }
    .travellerTicket-details-item1a1{
        flex-direction: row;
        width: 85%;
        font-size: 12px;
        gap: 1rem;
    }
    .travellerTicket-details-item1b{
        width: 90%;
        height: 50%;
        font-size: 12px;
        flex-wrap: wrap;
    }
    .travellerTicketDetails-item1b1{
        flex-wrap: wrap;
        gap: 5%;
    }
    .travellerTicketDetails-item1b1a{
        width: 45%;
    }
    .travellerTicketDetails-item1b1c{
        width: 60%;
        font-size: 10px;
    }
    .travellerTicket-details-item1-details{
        flex-direction: column;
        height: 120vh;
    }
    .travellerTicket-details-item1-details-item1{
        width:100%;
        height: 60%;
    }
    .travellerTicket-details-item1-details-item2{
        width:100%;
        height: 39%;
    }
}