.traveller-login-container{
  width: 100%;
  height: 100%;

}
.traveller-login-main{
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.traveller-login-main h1{
  width: 80%;
}
.Traveller-login-main-leftSide{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  /* padding-left: 4rem; */
}
.Traveller-login-main-RightSide{
  width: 50%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.traveller-login-mainContainer{
  width: 80%;
  height: 90%;
  display: flex;
  
}
.Traveller-login-main-RightSide img{
  height: 100%;
  width: 90%;
}
.traveler-login-leftside-item{
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
}
.traveler-login-leftside-item label{
  font-size: 14px;

}
/* .traveler-login-leftside-item input{
  padding-left: 10px;
  width: 60%;
  height: 45%;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.289);
} */
.traveller-login-selectLine{
  width: 65%;
  /* height: 10%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.traveller-login-btnHolder{
  width: 60%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.traveller-login-btnHolder button{
  width: 35%;
  height: 75%;
  border: none;
  background-color: red;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.p-tag-login{
  font-size: 12px;
  width: 60%;
  text-align: center;
}
.p-tag-login span{
  color: red;
  cursor: pointer;
}
.signup-terms-section{
  display: flex;
  gap: 0.5rem;
  font-size: 12px;
}
.signup-terms-section span{
  color: red;
  cursor: pointer;
}

@media only screen and (min-width:320px) and (max-width:576px) {
  .traveller-login-mainContainer{
    flex-direction: column-reverse;
    width: 95%;
  }
  .Traveller-login-main-leftSide{
    width: 100%;
    height: 60%;
    align-items: center;
  }
  .Traveller-login-main-RightSide{
    width: 100%;
    height: 40%;
  }
  .traveler-login-leftside-item{
    width: 90%;
  }
  .traveler-login-leftside-item input{
    width: 90%;
    height: 60%;
  }
}