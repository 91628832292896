.CompanyHomePage-main-Container{
  width: 100%;
  height: 250vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: black;
}
.companyHeader-Holder{
  width: 100%;
  height: 5%;
  padding-inline: 2rem;
  background-color: black;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.626);

}
.company-heroPage{
  width: 90%;
  height: 30%;
  background-color: bisque;
  display: flex;
}
.company-heropage-leftSection{
  width: 50%;
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}
.company-heropage-leftSection h1{
  padding-left: 2rem;
  font-size: 3rem;
  width: 100%;
  height: 50%;
}
.company-heropage-leftSection p{
  width: 100%;
  padding-left: 2rem;
  height: 10%;
}
.company-heropage-leftSection span{
  color: #FF725E;
}
.company-heropage-rightSection{
  width: 50%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-heropage-rightSection img{
  width: 80%;
  height: 80%;
}
.comapny-homepage-content{
  width: 100%;
  height: 40%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-homepage-container{
  width: 35%;
  height: 85%;
  border-radius: 2rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.company-homepage-container-section1{
  width: 90%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
.company-homepage-container-section1 h2{
  width: 100%;
  font-size: 1.8rem;
  text-align: center;
}
.company-homepage-container-section2{
  width: 90%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.company-homepage-container-section-items{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.company-homepage-container-section-items img{
  width: 20px;
  height: 20px;
}
.company-homepage-container-sectionBtn{
  width: 80%;
  height: 25%;
  background-color: #FF3131;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}
.company-homepage-container-section3{
  width: 100%;
  height: 15%;
  border-top: solid 2px rgba(0, 0, 0, 0.215);
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-homepage-container-section3-main{
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.company-homepage-container-section3-main p{
  width: 100%;
}
.company-homepage-container-section3-main span{
  width: 100%;
  color: #FF3131;
  font-weight: 600;
  cursor: pointer;
}

.companySide-footer{
  width: 90%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}





@media only screen and (min-width:320px) and (max-width:768px) {
  .company-heroPage{
    flex-direction: column-reverse;
    height: 90vh;
  }
  .CompanyHomePage-main-Container{
    height: max-content;
    min-height: 250vh;
  }
  .company-heropage-leftSection{
    width: 100%;
    height: 40%;
  }
  .company-heropage-rightSection{
    width: 100%;
    height: 60%;
  }
  .company-heropage-leftSection h1{
    font-size: 28px;
  }
  .company-heropage-leftSection span{
    font-size: 22px;
  }
  .comapny-homepage-content{
    height: 120vh;
  }
  .company-homepage-container{
    width: 90%;
    height: 70%;
  }
  .company-homepage-container-sectionBtn{
    height: 8vh;
  }
}