.companyHeader-container{
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.company-header-text{
  width: 40%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 2rem;
}
.company-header-text2{
  width: 40%;
  height: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 2rem;
}
.userProfile-option-holder2{
  padding: 2rem;
  background-color: white;
  position: absolute;
  right: 8rem;
  top: 2.5rem;
  z-index: 900000000000;
}
.company-header-text p{
  cursor: pointer;
}
.company-header-text2 p{
  cursor: pointer;
}
.company-header-text button{
  width: 20%;
  height: 50%;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #FF3131;
  cursor: pointer;
}
.company-header-text2 button{
  width: 20%;
  height: 50%;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #FF3131;
  cursor: pointer;
}
.company-header-text img{
  cursor: pointer;
  height: 20px;
  height: 20px;
}
.company-header-text2 img{
  cursor: pointer;
  height: 20px;
  height: 20px;
}
.company-header-text2-item12{
  width: 20%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.company-header-text2-item12 img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.company-header-text2-mobile{
  display: none;
}
.mobile-menuHolder{
  display: none;
}
.menuList_company{
  display: none;
}



@media only screen and (min-width:320px) and (max-width:768px) {
  .company-header-text2{
    display: none;
  }
  .company-header-text{
    display: none;
  }
  .companyHeader-container{
    padding-top: 1rem;
  }
  .companyHeader-container img{
    width: 50%;
  }
  .company-header-text2-mobile{
      display: flex;
      width: 15%;
      position: relative;
  }
  .company-header-text2-mobile-item{
    width: 40%;
    height: 20vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10vh;
    right: 0;
  }
  .mobile-menuHolder{
    display: flex;
    width: 30%;
    height: inherit;
    margin-right: 20px;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
  .menuList_company{
    display: flex;
    flex-direction: column;
    height: 90vh;
    width: 100%;
    position: absolute;
    background-color: #FF3131;
    top: 4.5rem;
    right: 0;
  }
  .company-header-text2-mobile-item p{
    /* width: 80%; */
    color: white;
    height: 30%;
  }
}