.fags-main-Container{
  width: 100%;
  height: max-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  gap: 2rem;
  margin-bottom: 2rem;
  
}
.fagsHeader-container{
  width: 100%;
  height: 10vh;
}
.faqs-container{
  width: 100%;
  height: 90vh;
}

.fag-questions-holder{
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  border:none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);


}
.faqs-searchbar{
  width: 100%;
  height: 8%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faqs-searchbar input{
  width: 90%;
  height: 80%;
  border: none;
  padding-left: 2rem;
}
.faqs-searchbar img{
  height: 15px;
  height: 15px;
  padding-right: 1rem;
  cursor: pointer;
}
.filterContainer{
  width: 10%;
  height: 95%;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}
.filterContainer img{
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.faqs-labels{
  width: 100%;
  height: 8%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}
.faqs-labels p{
  width: 40%;
  padding-left: 2rem;
}
.faqs-holder{
  width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 0.5rem;
}
.faqs-list-item{
  width: 100%;
  height: max-content;
  min-height: 10vh;
  border-bottom: solid 1px rgba(0, 0, 0, 0.209);
  display: flex;
  align-items: center;
}
.faqs-list-item p{
  width: 40%;
  padding-left: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
}
.faqs-title{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faqs-button{
  width: 15%;
  height: 70%;
  border: none;
  border-radius: 5px;
  background-color: #FF3131;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faqs-button button{
  width: 80%;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}
.faqs-footer{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.faqs-container2{
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.faqs-question-input-holder{
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.faqs-question-input-holder input{
  width: 60%;
  height: 60%;
  border: solid 1px rgba(0, 0, 0, 0.207);
  border-radius: 5px;
  padding-left: 1.5rem;
}
.faqs-answer-input-holder{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.faqs-answer-input-holder input{
  width: 60%;
  height: 85%;
  border: solid 1px rgba(0, 0, 0, 0.207);
  border-radius: 5px;
  padding-left: 1.5rem;

}
.faqs-complete-btnHolder{
  width: 100%;
  height: 15%;
}
.faqs-complete-btnHolder button{
  width: 25%;
  height: 80%;
  color: white;
  background-color: #FF3131;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}