.activity-card-container{
  width: 98%;
  height: 30%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
}
.activity-card-container-logo{
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.activity-card-container-logo img{
  width: 25px;
  height: 25px;
}
.activity-card-container-text{
  width: 85%;
  height: 90%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.activity-card-container-text-sec1{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
}
.activity-card-container-text-sec1 p{
  font-size: 0.8rem;
  font-weight: 400;
}
.activity-card-container-text-sec1 span{
  font-size: 0.6rem;
  font-weight: 200;
}