.travellerTracking-mainContainer{
    width: 100%;
    height: max-content;
    min-height: 50vh;
    display: flex;
    align-items: center;
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.travellerTracking-btnHolder{
    width: 90%;
    height: 30vh;
    background-color: black;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: white;
    flex-direction: column;
}
.travellerTracking-btnHolder input{
    width: 20%;
    height: 20%;
    border: none;
    border-radius: 5px;
    padding-left: 1rem;
}
.tickerId-details-holder{
    width: 90%;
    height: max-content;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 1rem;
}
.ticketID-details{
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ticketID-details-part1{
    width: 10%;
    height: 100%;
    display: flex;
    display: flex;
}
.ticketID-details-part2{
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ticketID-details-part2 img{
    width: 15px;
    height: 15px;
}
.ticketID-details-part1a{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.part1Dot-holder{
    width: 30%;
    height: 100%;
    padding-top: 20%;
}
.part1Dot-holder2{
    width: 30%;
    height: 100%;
    padding-top: 20%;
    display: flex;
    justify-content: flex-end;
}
.PartDot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: black;
}
.ticketID-detais{
    width: 90%;
    height: max-content;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.ticketID-detais-item1{
    width: 100%;
    align-items: center;
    height: 8vh;
    display: flex;
    justify-content: space-between;
}
.ticketId-details-title{
    width: 100%;
    height: 5vh;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.ticketID-detais-item1a{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.ticketID-detais-item1a img{
    width: 25px;
    height: 20px;
}
.ticketID-detais-item1a h4{
    color: red;
}
.ticketID-detais-item1 p{
    font-weight: 500;
}