.Terms-mainCOntainer{
  width: 100%;
  height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.terms-COntent{
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.terms-COntent p{
  width: 85%;
}