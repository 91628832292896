.driver-tripsDetails-mainCOntainer{
    width: 100%;
    height: max-content;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    padding-block: 2rem;
}
.driver-tripsDetails-holder{
    width: 90%;
    height: max-content;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.driver-tripsDetails-item1{
    width: 100%;
    height: max-content;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.driver-tripsDetails-item2{
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}
.driver-tripsDetails-item1a{
    width: 60%;
    height: 100%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.264);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-block: 2rem;
    gap: 1rem;

}
.driver-tripsDetails-item1b{
    padding-top: 2rem;
    width: 35%;
    height: 100%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.264);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-block: 5rem;

}
.driver-tripsDetails-item2a{
    width: 100%;
    height: 100%;
}
.driver-tripsDetails-item2a img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.driver-tripsDetails-item2 button{
    width: 99%;
    height: 7vh;
    color: white;
    background-color: red;
    border: none;
    position: absolute;
    bottom: 0;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}
.driver-tripsDetails-item1a-item1{
    width: 90%;
    height: 30%;
    display: flex; align-items: center;
    gap: 1rem;
}
.driver-tripsDetails-item1a-item1 img{
    width: 10%;
    height: 60%;
}
.driver-tripsDetails-item1a-item1a{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 60%;
    gap: 0.1rem;
}
.driver-tripsDetails-item1a-item1a h6{
    color: rgba(0, 0, 0, 0.445);
}
.driver-tripsDetails-item1a-item2{
    width: 90%;
    display: flex;
    height: 20%;
    align-items: center;
    justify-content: space-between;
}
.driver-tripsDetails-item1a-item2a{
    width: 25%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.driver-tripsDetails-item1a-item3{
    width: 90%;
}
.driver-tripsDetails-item1a-item4{
    width: 90%;
    height: max-content;
    padding-block: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.driver-tripsDetails-item1a-item4 span{
    font-size: 12px;
    color: red;
}
.driver-tripsDetails-item1b-item1{
    width: 80%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.driver-tripsDetails-item1b-item1 h5{
    color: red;
    font-weight: 500;
}
.driver-tripsDetails-item3{
    width: 100%;
    height: 20vh;
    background-color: black;
    /* position:sticky; */
    top: -1rem;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    color: white;
    padding-top: 2rem;
    padding-inline: 2rem;
    gap: 1.0rem;
}
.driver-tripsDetails-item3a{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3vh;
}
.driver-tripsDetails-item3a h5{
    color: red;
    width: 40%;
}
.driver-tripsDetails-item4{
    width: 90%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 2rem;
}
.driver-btn2{
    width: 20%;
    padding-block: 0.8rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.driver-btn1{
    width: 20%;
    padding-block: 0.8rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.269);
    color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.driver-btn3{
    width: 20%;
    padding-block: 0.8rem;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.driver-btn4{
    width: 20%;
    padding-block: 0.8rem;
    background-color: #FF3131;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}




@media only screen and (min-width:320px) and (max-width:768px) {
    .driver-tripsDetails-item1{
        flex-direction: column;
        gap: 2rem;
        
    }
    .driver-tripsDetails-item1a{
        width: 100%;
        height: 40vh;
    }
    .driver-tripsDetails-item1b{
        width: 100%;
        height: 30vh;
        padding: 0;
        padding-top: 1rem;
    }
    .driver-tripsDetails-item2{
        height: 50vh;
    }
    .driver-tripsDetails-item3{
        padding: 0;
        padding-top: 1rem;
        justify-content: center;
        padding-left: 1.5rem;
        
    }
    .driver-tripsDetails-item3a{
        padding: 0;
        padding-top: 0.2rem;   
    }
    .driver-tripsDetails-item3a h5{
        width: 60%;
        font-size: 12px;
        height: max-content;
    }
    .driver-tripsDetails-item3a h4{
        width: 30%;
        font-size: 14px;
        height: max-content;
    }
    .driver-btn4{
        width: 45%;
    }
    .driver-btn3{
        width: 45%;
    }
    .driver-btn2{
        width: 45%;
    }
    .driver-btn1{
        width: 48%;
    }
    .driver-tripsDetails-item4{
        gap: 1rem;
    }
}
