.driverdashboard-mainContainer{
    width: 100%;
    height: max-content;
    padding-block: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.driverdashboard-main{
    width: 90%;
    height: max-content;
    min-height: 40vh;
    display: flex;
    justify-content: space-between;
}
.driverdashboard-main-left{
    width: 25%;
    height: max-content;
    min-height: 40vh;
    border-radius: 10px;
    border: solid 1px black;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.257); */
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.driverdashboard-main-right{
    width: 70%;
    height: max-content;
    min-height: 40vh;
    border-radius: 10px;
}
.driverdashboard-main-left-sec1{
    width: 90%;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.driverdashboard-main-left-sec2{
    width: 90%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.driverdashboard-main-left-sec2a{
    width: 25%;
    height: 90%;
}
.driverdashboard-main-left-sec2a img{
    width: 90%;
    height: 90%;
    border-radius: 50%;
}

.driverdashboard-main-left-sec2b{
    width: 70%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.3rem;
}
.driverdashboard-main-left-sec2b h5{
    font-weight: 500;
    color: red;
}
.driverdashboard-main-left-sec3{
    width: 90%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.driverdashboard-main-left-sec3a{
    width: 100%;
    min-height: 18%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.driverdashboard-main-right-top{
    width: 100%;
    height: max-content;
    display: flex;
    min-height: 30vh;
    flex-direction: column;
    gap: 1.5rem;
}
.driverdashboard-main-right-top-item{
    width: 100%;
    height: 15vh;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.257);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-inline: 2rem;

}
.driverdashboard-main-right-top-item img{
    width: 15%;
    height: 80%;
    border-radius: 10px;
}
.driverdashboard-main-right-top-itema{
    width: 25%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}
.driverdashboard-main-right-top-itema1{
    display: flex;
    height: 30%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}
.driverdashboard-main-right-top-itema-h5{
    width:100%;
    font-size: 13px;
    font-weight: 500;
    padding-inline: 10%;
}



@media only screen and (min-width:320px) and (max-width:768px) {
    .driverdashboard-main{
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .driverdashboard-main-left{
        width: 90%;
    }
    .driverdashboard-main-right{
        width: 90%;
    }
    .driverdashboard-main-right-top-item{
        padding: 0;
        gap: 0.2rem;

    }
    .h5-hidden{
        display: none;
    }
    .driverdashboard-main-right-top-item img{
        width: 20%;
        height: 50%;
    }
    .driverdashboard-main-right-top-itema{
        width: 35%;
    }
}